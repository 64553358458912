import { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";

function Riot ({ setBg }) {
    useEffect(() => {
        setBg('bg-riot-m md:bg-riot md:bg-right-top');
    }, [setBg]);

    return (
        <div className="w-full flex flex-col">
            <div className="h-[320px] md:h-[100px]" />
            <div className="ml-6 md:ml-12 mb-10 font-goodtimes text-primary-dark-yellow text-left text-6xl md:text-8xl md:w-1/2">
                <div>RIOT GEARS</div>
            </div>
            <div className="mx-6 md:ml-12 mb-8 md:w-2/5">
                <svg viewBox="0 0 758 513.2">
                <g id="g44" transform="matrix(1.3429,0,0,1.3429,0,-0.00671446)">
                    <polygon id="polygon6" stroke="#FFFFFF" strokeWidth="5.2776" strokeMiterlimit="13.429" points="414.4,380.2 465.9,291 
                        414.4,201.9 311.5,201.9 260,291 311.5,380.2 	"/>
                    <path id="path8" fill="#FFFFFF" d="M417.2,257.8c0.6,16.3-4.1,30.5-13.9,42.7c1.7-6.6,3.6-13.3,5-20.2c0.9-4.4,1.2-8.9,1.5-13.3
                        c0.1-0.9-0.9-2.4-1.7-2.6c-8.7-2.2-13.6-8-15.8-16.4c-0.5-2.1-1.5-3.1-3.6-3.6c-17.1-4.1-34.2-4.1-51.4,0c-2.2,0.5-3.2,1.6-3.8,3.8
                        c-2.2,8.3-7.1,14.1-15.7,16.1c-2.2,0.5-1.9,1.9-1.8,3.3c0.7,10.8,2.5,21.3,6.5,31.4c0.2,0.5,0.4,1.1,0.4,1.7
                        c-10.3-12.4-14.9-26.6-14.3-42.8c1-0.1,1.6-0.2,2.4-0.2c10-0.3,15.4-5.2,16.2-15.2c0.2-2.2,0.9-3,2.9-3.6c22-6.4,44-6.4,65.9,0
                        c1.9,0.6,2.5,1.4,2.7,3.4c0.8,10.2,6.1,15,16.4,15.4C415.7,257.6,416.3,257.7,417.2,257.8L417.2,257.8z"/>
                    <path id="path10" fill="#FFFFFF" d="M411.6,296.5c-2.8,5.6-5.3,11.5-8.5,16.9c-8.6,14.5-20.5,25.6-35.5,33.4
                        c-3.1,1.6-5.8,1.9-9,0.2c-21-10.9-35.4-27.6-43.7-49.8c-0.1-0.3-0.1-0.6-0.2-1.6c0.9,1.2,1.4,1.8,1.8,2.5
                        c9.3,13.7,19.5,26.7,33.6,35.7c14,9,11.4,9,25.5-0.1c13.9-8.9,24-21.7,33.2-35.2c0.6-0.8,1.2-1.7,1.8-2.5
                        C411,296.2,411.3,296.3,411.6,296.5L411.6,296.5z"/>
                    <polygon id="polygon12" stroke="#FFFFFF" strokeWidth="5.2776" strokeMiterlimit="13.429" points="238.4,278.9 289.9,189.7 
                        238.4,100.5 135.5,100.5 84,189.7 135.5,278.9 	"/>
                    <path id="path14" fill="none" stroke="#FFFFFF" strokeWidth="4.7673" strokeMiterlimit="13.429" d="M146.4,146
                        c4.9-1.1,9.5-2.2,14.1-3.2c0.7-0.1,1.4-0.2,2-0.4c4.5-1.5,7.9-1.4,11.9,2.6c8.1,8.2,21.8,6.8,29.5-2.1c1.5-1.7,2.8-2,4.8-1.5
                        c6.7,1.6,13.4,3,20.4,4.6c-1.4,3.8-2.8,7.4-4,11c-3.7,10.9-3.8,22.3-4.5,33.6c-0.8,13.9-1.5,27.8-2.1,41.7
                        c-0.1,2.5-1.1,3.1-3.4,3.1c-18.2-0.1-36.3-0.1-54.5,0c-2.5,0-3.2-0.9-3.3-3.2c-0.8-16.4-2-32.7-2.6-49.1
                        C154.2,170.4,152.1,158.2,146.4,146L146.4,146z"/>
                    <path id="path16" fill="none" stroke="#FFFFFF" strokeWidth="4.7673" strokeMiterlimit="13.429" d="M131.4,167.6
                        c4.3-6.5,8.5-12.8,13.1-19.8c0.7,1.9,1.3,3.2,1.7,4.5c1.9,7,3.7,14.1,5.6,21.1c0.8,3-1.6,4.4-3.2,6.2c-0.2,0.3-1.4,0.1-1.8-0.2
                        C141.7,175.6,136.7,171.7,131.4,167.6L131.4,167.6z"/>
                    <path id="path18" fill="none" stroke="#FFFFFF" strokeWidth="4.7673" strokeMiterlimit="13.429" d="M231.3,147.9
                        c4.6,6.9,8.7,13.1,13.1,19.6c-3.6,2.9-7.1,5.6-10.6,8.4c-1,0.8-2,1.6-3.1,2.3c-1.1,0.6-3.1,1.8-3.4,1.5c-1.4-1.6-3.4-3.6-3.2-5.2
                        C225,165.5,227.9,157,231.3,147.9L231.3,147.9z"/>
                    <polygon id="polygon20" stroke="#FFFFFF" strokeWidth="5.2776" strokeMiterlimit="13.429" points="415.7,180.3 467.2,91.1 
                        415.7,2 312.7,2 261.3,91.1 312.7,180.3 	"/>
                    <path id="path22" fill="#FFFFFF" d="M393,122.7c-19.4,5.5-38.5,5.5-57.8,0v1.9v15.1c0,2-0.5,2.5-2.5,2.5c-2.9,0-5.7,0-8.6,0
                        c-2,0-2.5-0.5-2.5-2.5c0-6.8,0-13.6,0-20.4c0-1.1-0.4-1.6-1.3-2.3c-2.1-1.6-4-3.3-5.6-5.3c-0.7-0.9-0.6-2.7-0.5-4
                        c0.1-1.8,0.8-3.6,0.9-5.4c0.5-6.3,1.7-12.5,3.9-18.5c1.6-4.4,2.4-9,3.5-13.5c3.2-12.4,10.7-21.4,22.5-26.3
                        c11.8-4.9,24.1-5.1,36.1-0.8c14.1,5.1,22.3,15.5,25.3,30.1c0.9,4.3,2.2,8.4,3.6,12.6c2.1,6.1,2.8,12.4,3.3,18.8
                        c0.1,0.8,0.3,1.7,0.6,2.4c0.7,2,0.7,3.9-0.8,5.5c-1.7,1.8-3.3,3.8-5.7,4.8c-0.4,0.2-0.7,1-0.7,1.6c0,6.8,0,13.6,0,20.4
                        c0,2.4-0.4,2.8-2.8,2.8h-8.3c-2,0-2.5-0.5-2.5-2.5v-15.1L393,122.7L393,122.7z M408.9,97.1c0.2-0.1,0.4-0.1,0.5-0.2
                        c-0.9-3.3-1.7-6.6-2.6-9.8c-1.2-4.4-2.8-8.7-3.7-13.2c-2.5-12.2-9-21.3-20.4-26.5c-9.9-4.5-20.4-4.9-30.9-2.2
                        c-14.3,3.7-23.2,13.1-26.4,27.6c-0.6,2.7-1.1,5.3-1.9,7.9c-1,3.5-2.3,6.9-3.4,10.3c-0.3,0.9-0.5,1.9-0.6,2.9c-0.2,1-0.3,2-0.5,3
                        c0.5-0.3,0.7-0.7,0.9-1.1c1.2-3.3,3.6-5.5,6.8-6.8c1.1-0.4,2.1-1,3.2-1.4c16.8-6.4,34.1-8.2,51.8-4.7c7.3,1.4,14.4,3.4,21,6.8
                        c2.2,1.1,3.9,2.7,4.8,5C408,95.5,408.5,96.3,408.9,97.1L408.9,97.1z M335.2,108c0,3.3,0,6.6,0,9.9c0,0.9,0.2,1.3,1.1,1.6
                        c18.5,5.3,37,5.3,55.5,0c0.9-0.2,1.2-0.7,1.2-1.6c0-6.6,0-13.3,0-19.9c0-1-0.4-1.4-1.2-1.7c-18.4-6.8-36.9-6.8-55.3,0
                        c-1,0.4-1.3,0.9-1.3,1.9C335.3,101.5,335.2,104.7,335.2,108L335.2,108z M319.6,112c2.2-4.5,4.4-8.8,6.6-13.2
                        c0.7-1.5,1.7-2.5,3.2-3.2c23.2-10.4,46.3-10.4,69.5,0c1.5,0.7,2.5,1.7,3.2,3.2c1.9,3.9,3.9,7.8,5.9,11.7c0.2,0.5,0.5,0.9,0.8,1.4
                        c2.6-2.2,2.6-2.3,1.2-5.1c-1.2-2.4-2.7-4.8-3.6-7.3c-1.7-4.9-5.3-7.4-9.9-9.1c-15.5-5.6-31.3-7.6-47.6-4.6
                        c-7.7,1.4-15.4,3.3-22.3,7.1c-1.1,0.6-2.1,1.7-2.8,2.8c-2,3.6-3.7,7.3-5.6,11C316.9,109.7,316.9,109.9,319.6,112L319.6,112z
                        M331.8,131.9V98.6c-0.1-0.1-0.2-0.2-0.4-0.3c-0.7,0.7-1.7,1.3-2.2,2.2c-1.3,2.3-2.4,4.6-3.5,7c-0.4,0.8-0.6,1.7-0.6,2.6
                        c0,6.8,0,13.6,0,20.3c0,0.5,0,1.1,0.1,1.6L331.8,131.9L331.8,131.9z M403.1,131.9c0-4.9-0.3-9.7,0.1-14.4c0.5-6.2-0.7-11.8-4.1-17
                        c0,0,0-0.1-0.1-0.1c-0.7-0.7-1.5-1.4-2.2-2.1l-0.3,0.3v33.4H403.1L403.1,131.9z M325.2,135.4v3.2h6.5v-3.2H325.2z M403,138.7v-3.2
                        h-6.5v3.2H403z"/>
                    <path id="path24" fill="#FFFFFF" d="M331.8,76.6c0.5-9.6,5.2-16.8,12.9-22.2c1.8-1.3,3.9-2.3,5.9-3.3c1.7-0.8,2.6-0.6,3.1,0.4
                        c0.5,1.1,0,1.9-1.7,2.6c-4.6,2-8.5,4.9-11.7,8.8c-3.4,4.2-5.1,9.1-5.2,14.5c0,1.1-0.5,1.9-1.7,1.9c-1.2,0-1.8-0.8-1.7-2L331.8,76.6
                        L331.8,76.6z"/>
                    <rect id="rect26" x="452.5" y="214.4" fill="#F5F5D8" width="111.9" height="34.3"/>
                    
                        <text transform="matrix(0.7447 0 0 0.7447 463.3003 238.4502)" fill="#4D4927" fontFamily="'Good Times'" fontSize="26.7371px">ShieLd</text>
                    <rect id="rect32" y="230.3" fill="#F5F5D8" width="97.8" height="34.3"/>
                    
                        <text transform="matrix(0.7447 0 0 0.7447 10.8201 254.3098)" fill="#4D4927" fontFamily="'Good Times'" fontSize="26.7371px">SUITS</text>
                    <rect id="rect38" x="132.3" y="18.1" fill="#F5F5D8" width="137.8" height="34.3"/>
                    
                        <text transform="matrix(0.7447 0 0 0.7447 143.1403 42.1101)" fill="#4D4927" fontFamily="'Good Times'" fontSize="26.7371px">HELMETS</text>
                </g>
                </svg>
            </div>

            <div className="flex flex-col mb-12 md:mx-9">
                <div className="flex flex-col md:flex-row">
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/suit01">
                            <img className="h-auto w-[200%]" src={require('./assets/03-01-riot.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-suits bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/helmet05">
                            <img className="h-auto w-[200%]" src={require('./assets/03-02-riot.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-helmets bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/shield01">
                            <img className="h-auto w-[200%]" src={require('./assets/03-03-riot.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-shield bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                </div>

                <div className="flex flex-col md:flex-row">
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/shield02">
                            <img className="h-auto w-[200%]" src={require('./assets/03-04-riot.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-shield bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/shield03">
                            <img className="h-auto w-[200%]" src={require('./assets/03-05-riot.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-shield bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/shield04">
                            <img className="h-auto w-[200%]" src={require('./assets/03-06-riot.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-shield bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                </div>

                <div className="flex flex-col md:flex-row">
                    <div className="mx-6 my-3 md:mx-3 relative grow md:block hidden">
                        <img className="h-auto w-[200%]" src={require('./assets/02-00-bullet.jpg')} alt="" />
                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center"><Logo width="130"/></div>
                    </div>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/shield05">
                            <img className="h-auto w-[200%]" src={require('./assets/03-07-riot.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-shield bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <div className="mx-6 my-3 md:mx-3 relative grow md:block hidden">
                        <img className="h-auto w-[200%]" src={require('./assets/02-00-bullet.jpg')} alt="" />
                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center"><Logo width="130"/></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Riot;
