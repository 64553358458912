import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Apparel06 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-apparel06-m md:bg-apparel06 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">APPAREL</span>
                        <div className="font-goodtimes text-4xl mt-5 md:text-5xl text-primary-yellow w-11/12">
                            <div>ATG ARMORED DRESS VEST</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="mx-6 md:ml-12">
                        <div className="mt-5 font-bold">
                            <div>A discrete way to conceal a lightweight, armored NIJ IIIA bulletproof liner</div>
                        </div>
                        <div className="w-11/12">
                            It looks sharp under any suit coat or sports jacket and can be worn during all type of events. Torso coverage with protective extensions on the sides and up to the collar bone area provides ballistic protection from most handgun ammunition.
                        </div>
                    </div>
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Panels remove easily for cleaning.
                            </li>
                            <li>
                                Lightweight protection.
                            </li>
                            <li>
                                Classic piece of clothing with timeless wear.
                            </li>
                            <li>
                                Suit jacket doesn’t have to be worn over the vest.
                            </li>
                            <li>
                                Nearly 500 square inches of coverage.
                            </li>
                            <li>
                                Weighs approximately 4 lbs.
                            </li>
                            <li>
                                Meets NIJ IIIA standards that will stop a 357 Magnum, 44 Magnum, 9mm, .45, hollow point ammunition and more.
                            </li>
                            <li>
                                Provides identical anti-ballistic protective coverage area as traditional law enforcement body armor vests.
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="flex flex-col md:flex-row md:mx-6">
                        <div className="mx-24 md:mx-16 mt-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel06-01.jpg')} alt="" draggable="false" />
                        </div>
                        <div className="mx-24 md:mx-16 mt-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel06-02.jpg')} alt="" draggable="false" />
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:mx-6">
                        <div className="mx-24 md:mx-16 mt-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel06-03.jpg')} alt="" draggable="false" />
                        </div>
                        <div className="mx-24 md:mx-16 mt-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel06-04.jpg')} alt="" draggable="false" />
                        </div>
                    </div>
                 </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Apparel06;
