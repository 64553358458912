import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Shield05 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-shield05-m md:bg-shield05 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[290px] md:h-[290px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">SHIELD</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-5/6">
                            <div>HANDHELD RIOT SHIELD</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        TECH SPECS
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Specs</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Protection : Non-ballistic threats
                                </li>
                                <li>
                                    Weight : ~ 6 lbs (36” unit)
                                </li>
                            </ul>
                        </div>
                        <div className="mt-3 font-bold">Features</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Interlocking Flared Edges
                                </li>
                                <li>
                                    Sturdy Aluminum Handle
                                </li>
                                <li>
                                    Deterrent Bump (optional)
                                </li>
                                <li>
                                    Heavy-Duty Armband
                                </li>
                                <li>
                                    Shield-Guiding Thigh Pad
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Superior protection in a lightweight design.</div>
                        <div className="w-11/12">
                            The Handheld Riot Shield (NBS) is designed for use in corrections and law enforcement applications. Particularly effective for crowd and riot control, the NBS is lightweight, shatter resistant and capable of withstanding impacts from bricks, bottles, splash and rocks. NBS units also stand up to all types of swinging strikes.
                        </div>
                    </div>

                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mx-16 my-6 md:mx-56">
                        <img className="h-auto w-[200%]" src={require('./assets/03-shield05-01.jpg')} alt="" draggable="false" />
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="w-11/12">
                            The NBS is available in several different models to meet your specific needs, including 36” or 48” lengths with surface shapes varying by model. It is delivered fully assembled and custom decals are available.
                        </div>
                    </div>

                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Shield05;
