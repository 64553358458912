import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Light01 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-light01-m md:bg-light01 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[260px] md:h-[178px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">LIGHTS</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-11/12">
                            <div>GUARDIAN ANGEL INFRARED WEARABLE SAFETY LIGHT</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-2/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12 md:flex md:flex-row">
                        <ol className="list-decimal pl-8 pr-6 pt-2 md:pb-2 font-bold">
                            <li>
                                Lightweight - <span className="font-normal">Approximately 3 Oz</span> 
                            </li>
                            <li>
                                Durable - <span className="font-normal">Water proof, dust proof, shock proof, IP68 Rated</span> 
                            </li>
                            <li>
                                Long Range Visibility - <span className="font-normal">Tested 5+ miles of visibility using high powered LEDs, 360° WEARABLE LIGHTING</span> 
                            </li>
                            <li>
                                Stealth Work Light Feature - <span className="font-normal">Single red LED work light for low light environments</span>
                            </li>
                            <li>
                                Enhanced “Take Down” Work Light Feature - <span className="font-normal">4 high powered white LEDs on the front offers hands free work light</span> 
                            </li>
                            <li>
                                Four Different Brightness Settings - <span className="font-normal">Low, Medium, High & MAX</span>
                            </li>
                            <li>
                                Extreme Battery Life - <span className="font-normal">Rechargeable lithium ion battery using Type-C to Type-A USB charging cable (included)</span>
                            </li>
                            <li>
                                Built in Magnet Mounting System - <span className="font-normal">Built in Neodymium magnet to attach to any metallic surface.</span>
                            </li>
                            <li>
                                360° LIGHTING Patterns - <span className="font-normal">Front and Rear LEDs can be set as Constant On, Constant Flashing or Scrolling Sequence</span>
                            </li>
                        </ol>
                        <ol className="list-decimal pl-8 pr-6 pb-2 md:pt-2 font-bold" start="10">
                            <li>
                                Independent Light Controls - <span className="font-normal">4 different light controls provide maximum lighting  versatility, including an all flashing emergency mode 360° LIGHTING</span> 
                            </li>
                            <li>
                                SOS Flash Pattern Mode - <span className="font-normal">Quick button hold sets device to SOS flash mode</span>
                            </li>
                            <li>
                                Base Mount with 6” Velcro Strap (included) - <span className="font-normal">Wear the mount under clothing or use the velcro strap to secure mount to tactic vest or gear.</span>
                            </li>
                            <li>
                                Independent Top Light Feature - <span className="font-normal">Single 850 nano meter infrared LED with dome lens for directed beam can be operated independently - Flashing Pattern</span>
                            </li>
                            <li>
                                Easy Read Battery Life Indicator - <span className="font-normal">Press and Hold “Rear” button for 3 second solid battery level indicator</span>
                            </li>
                            <li>
                                Easy Access Charging Port - <span className="font-normal">Port Door on side to allow device charging while attached to a mount</span>
                            </li>
                            <li>
                                Additional Hard Mounting Options - <span className="font-normal">Now has 4 threaded inserts for more hard mounting directions</span>
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Light01;
