import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Goggle01 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-goggle01-m md:bg-goggle01 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[420px] md:h-[300px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">GOGGLES</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-5/6">
                            <div>WILEY X SPEAR</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Meets Ballistic MIL-V-43511C standards
                            </li>
                            <li>
                                2.5mm lenses provide 100% UV protection
                            </li>
                            <li>
                                Spear Goggle Bag (AN571) included
                            </li>
                            <li>
                                Prescription insert adaptable to Talon, G-Eye and Patriot
                            </li>
                            <li>
                                Symmetrical venting system reduces lens fogging
                            </li>
                            <li>
                                NVG compatible
                            </li>
                            <li>
                                Low-profile, multi-functional design
                            </li>
                        </ol>
                    </div>
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            The Wiley X Spear goggles are made for any tactical operation. These goggles feature a lowprofile design packed with powerful features, including a Top Down ventilation system to resist fogging, NVG compatibility and lenses that meet ballistic MIL-V-43511C standards.
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            Swivel hinges allow these goggles to be adaptable for use with a helmet or without. The frame and straps are heat-resistant and the strap material incorporates a Tri-Glide system to adjust to an ideal length and fit.
                        </div>    
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            A 90-degree wrap-back on the frame eliminates tunnel vision and the 2.5mm lenses keep your eyes safe with 100% UV protection, making these goggles ready to tackle any challenge.
                        </div>    
                    </div>
                    <div className="mt-6 mx-8 md:mx-20">
                        <img className="h-auto w-[200%]" src={require('./assets/02-goggle01-01.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Goggle01;
