import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Shield02 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-shield03-m md:bg-shield03 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[290px] md:h-[290px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">SHIELD</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-5/6">
                            <div>ROUND RIOT SHIELD</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        TECH SPECS
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Shield</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Extremely Resistant Polycarbonate
                                </li>
                                <li>
                                    Strength : Anti-break and anti-crack
                                </li>
                                <li>
                                    Width : 55.8 cm
                                </li>
                                <li>
                                    Height : 59 cm
                                </li>
                                <li>
                                    Weight : 2.38 kg
                                </li>
                                <li>
                                    Color : Transparent
                                </li>
                            </ul>
                        </div>
                        <div className="mt-3 font-bold">Handle Grip</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Iron Coated Contoured Rubber
                                </li>
                                <li>
                                    Position : 45 degree
                                </li>
                            </ul>
                        </div>
                        <div className="font-bold mt-3">Baton Mounting</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Polycarbonate
                                </li>
                                <li>
                                    Placement : Top and bottom right side of the shield
                                </li>
                                <li>
                                    Bolt : Each mounting uses 1 bolt
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mx-16 my-6 md:mx-36">
                        <img className="h-auto w-[200%]" src={require('./assets/03-shield03-01.jpg')} alt="" draggable="false" />
                    </div>

                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Shield02;
