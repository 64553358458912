import { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import { IoMenu, IoClose } from "react-icons/io5";

function Navbar () {
    const [menuBurger, setMenuBurger] = useState(true);

    const handleMenuBurger = (e) => {
        setMenuBurger(!menuBurger);
    }

    const handleMenuItem = (e) => {
        setMenuBurger(true);
    }

    return (
        <nav className="md:flex md:items-center md:justify-center bg-black">
            <div className="flex items-center justify-between">
                <button className="my-1 md:mx-4 mx-2"><Link to="/"><Logo height="50px" /></Link></button>
                <ul className={`text-primary-yellow whitespace-nowrap text-lg font-interbold md:bg-transparent bg-black bg-opacity-90 md:flex md:items-center z-[100] md:z-auto md:static absolute w-full left-0 md:w-auto md:py-0 py-4 md:pl-0 pl-7 md:opacity-100 ${menuBurger ? "opacity-0 top-[-400px]" : "opacity-100 top-[90px]" }`}>
                    <li className="md:mx-4 md:my-0 mx-6 my-5">
                        <button onClick={(e) => handleMenuItem()}><Link to="/fullbody">FULL BODY ARMOR GEARS</Link></button>
                    </li>
                    <li className="md:mx-4 md:my-0 mx-6 my-5">
                        <button onClick={(e) => handleMenuItem()}><Link to="/bullet">BULLETPROOF GEARS</Link></button>
                    </li>
                    <li className="md:mx-4 md:my-0 mx-6 my-5">
                        <button onClick={(e) => handleMenuItem()}><Link to="/riot">RIOT GEARS</Link></button>
                    </li>
                    <li className="md:mx-4 md:my-0 mx-6 my-5">
                        <button onClick={(e) => handleMenuItem()}><Link to="/duty">DUTY GEARS</Link></button>
                    </li>
                </ul>
                <button className="md:hidden inline mx-2 text-primary-yellow text-5xl" onClick={(e) => handleMenuBurger()}>
                    { menuBurger && <IoMenu /> }
                    { !menuBurger && <IoClose /> }
                </button>
            </div>
            <div className="font-acier-outline text-primary-yellow md:text-3xl text-2xl whitespace-nowrap md:mx-4 md:w-auto w-full md:text-left text-center">FIRST LINE OF DEFENSE</div>
        </nav>
    );
}

export default Navbar;
/*
import { Link } from 'react-router-dom';

function Navbar ()  {
    return (
        <div className="text-primary-yellow flex flex-row whitespace-nowrap text-lg font-interbold pl-24">
            <button className="py-3 px-8"><Link to="/fullbody">FULL BODY ARMOR GEARS</Link></button>
            <button className="py-3 px-8"><Link to="/bullet">BULLETPROOF GEARS</Link></button>
            <button className="py-3 px-8"><Link to="/riot">RIOT GEARS</Link></button>
            <button className="py-3 px-8"><Link to="/duty">DUTY GEARS</Link></button>
        </div>
    );
}

export default Navbar;
*/