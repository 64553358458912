import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Helmet05 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-helmet05-m md:bg-helmet05 md:bg-left-top'); 
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[288px] md:h-[250px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">HELMET</span>
                        <div className="font-goodtimes text-4xl mt-5 md:text-5xl text-primary-yellow w-11/12 md:w-full">
                            <div>ATG S600 RIOT HELMET</div>
                            <div className="text-xl md:text-2xl">WITH DARK SMOKE FACE SHIELD</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        TECH SPECS
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Shell</div>
                        <div className="w-11/12">
                            FIBERGLASS full coverage shell with multiple layer impact absorbing materials. Shell incorporates an integral molded visor and side attachment and thumb screws for attaching the Riot Control Face shield. Three shell sizes, small/medium, large & extra-large. Shell edge protected with tough molded metal core scuff-proof edging. Dull matte finish.
                        </div>
                        <div className="mt-3 font-bold">Liner</div>
                        <div className="w-11/12">
                            Semi-resilient throughout the inside of the helmet.
                        </div>
                        <div className="mt-3 font-bold">Inner Shell</div>
                        <div className="w-11/12">
                            MODEL S600 energy absorbing material full inner shell of ABS molded plastic to seal off the liner and to protect the liner from solvents, hair oils and body perspiration. The .030” thick ABS inner shell shall be cleanable with mild soap solution.
                        </div>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Face Shield</div>
                        <div className="w-11/12">
                            MODEL S600FG Maximum coverage and protection, injection molded high-impact polycarbonate face shield. Optical grade .100 inches thick. Easily adapts to Gas Mask use age. The face shield allows full 90°rotation with a cam-actionpivot, incorporating full up to full down with a positive lock in either position. Face shield is held firmly in place and replaceable by means of metal aluminum band, spring steel clip and thumb screws. The face shield incorporates a neoprene seal to provide a liquid seal between the face shield and the helmet. The edges of the Face shield shall be a smooth rounded edge. The S600FG Model has the addition of a heavy # 9 wire faceguard affixed to the exterior of the face shield for forced cell entry duty and optional tiedowns to secure in the down position. Face shields are replaceable in the field and meet or exceed the requirements of Z87.1, VESC-8 and NIJ0104.02.
                        </div>
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Helmet05;
