import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Baton01 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-baton01-m md:bg-baton01 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[260px] md:h-[300px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">BATON</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-11/12 md:w-5/6">
                            <div>ATG LOC BATON</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Expanded length: 21" or 26"
                            </li>
                            <li>
                                Retracted length: 7.75" or 9.5"
                            </li>
                            <li>
                                4130 steel construction
                            </li>
                            <li>
                                Black nickel finish
                            </li>
                            <li>
                                Santoprene handle provides firm grip in the most adverse conditions
                            </li>
                        </ol>
                    </div>
                    <div className="mt-6 mb-8 mx-8 md:mx-20">
                        <img className="h-auto w-[200%]" src={require('./assets/04-baton01-01.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            The distinctive Strike Force grip of the ATG Loc Baton is complemented by 4130 steel shafts and a black nickel finish. The Sentry Baton has an aluminum cap and aluminum base retaining clip. Designed forthe security professional, the Sentry Series offers a moderately priced alternative to ATG Friction Loc expandables.
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            A black nickel finish makes these batons sleek, and the 4130 steel shaft construction makes them virtually indestructible. For a powerful psychological deterrent and a baton that sets itself apart in quality, durability and performance, the ATG Loc Baton delivers.
                        </div>    
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Baton01;
