import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Helmet02 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-helmets02-m md:bg-helmets02 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[420px] md:h-[178px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">HELMETS</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow md:text-primary-dark-yellow w-5/6">
                            <div>ATG THE PROTECTOR HELMET</div>
                        </div>
                        <hr />
                        <div className="text-primary-yellow mt-3">
                            <div>COMFORTABLE AND ADAPTABLE, THIS HELMET IS THE CUTTING EDGE OF BALLISTIC HELMET TECHNOLOGY</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Tested to NIJ 0106.01 standards
                            </li>
                            <li>
                                Level IIIA ballistic protection
                            </li>
                            <li>
                                Rated for 9mm and .44 Magnum
                            </li>
                            <li>
                                Size: 20.5” - 22.4” (small/medium)
                            </li>
                            <li>
                                Weight: 2.7 pounds (small/medium)
                            </li>
                            <li>
                                Size: 22.4” - 24.4” (large/extra large)
                            </li>
                            <li>
                                Weight: 3.1 pounds (large/extra large)
                            </li>
                            <li>
                                Thickness: 0.3”
                            </li>
                            <li>
                                Materials: high-strength composite construction
                            </li>
                            <li>
                                Finish: matte polyurethane paint with low infra red signature
                            </li>
                            <li>
                                4-point harness is fully adjustable and removable
                            </li>
                            <li>
                                Adjustable chin strap with side release buckle
                            </li>
                            <li>
                                Allows for night-vision goggle (NVG) bracket integration and side accessory rails
                            </li>
                            <li>
                                Based on the Advanced Combat Helmet (ACH) by the U.S. Army
                            </li>
                            <li>
                                Designed to defeat 95% of ammunition used in shooting incidents
                            </li>
                            <li>
                                Nylon carry bag included
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            Rated for protection against 9mm and 44 Magnum, the AR500 Armor Protector Helmet offers vital ballistic protection for one of the most critical areas of the body. Made from high-strength composite materials, this 0.3” thick helmet is designed to defeat a wide range of ballistic threats.
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            It's NIJ 0106.01 Level IIIA compliant and based on the Advanced Combat Helmet, the Army's latest design. It comes with an adjustable, removable 4-point harness. The harness's chin strap is made with a side release buckle closure for the ultimate ease and comfort.
                        </div>    
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            The helmet is designed to integrate night-vision goggle brackets so you can see in all settings. It's finished with a matte polyurethane paint which offers a low infrared signature, and a nylon carrying bag is included.
                        </div>    
                    </div>
                    <div className="flex flex-col md:flex-row md:ml-6">
                        <div className="mx-28 my-6 md:mx-6">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets02-01.jpg')} alt="" draggable="false" />
                        </div>
                        <div className="mx-28 my-6 md:mx-6">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets02-02.jpg')} alt="" draggable="false" />
                        </div>
                        <div className="mx-28 my-6 md:mx-6">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets02-03.jpg')} alt="" draggable="false" />
                        </div>
                    </div>

                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Helmet02;
