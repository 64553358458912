import { useEffect } from "react";
import Logo from "./Logo";

function Home ({ setBg }) {
    useEffect(() => {
        setBg('bg-home-m md:bg-home md:bg-left-top');
    }, [setBg]);

    return (
        <div className="w-full flex flex-row">
            <div className="w-1/2 md:block hidden"></div>
            <div className="w-1/2 md:flex flex-col h-full items-center justify-center hidden mx-16">
                <div className="w-full min-w-[770px]"><Logo /></div>
                <div className="w-full pt-3 font-acier text-primary-yellow text-7xl text-center whitespace-nowrap">FIRST LINE OF DEFENSE</div>
                <div className="text-primary-yellow text-right w-full text-2xl font-semibold pt-7">
                    <p className="text-3xl font-extrabold">American Tactical Gear, LLC</p>
                    <p>1201 North Market Street, Suite 111</p>
                    <p>Wilmington, Delaware, 19801</p>
                    <p>Phone : (302) 403-2422</p>
                    <p>Fax : (302) 403-2423</p>
                </div>
            </div>
            <div className="md:hidden text-primary-yellow text-right w-full text-xl font-semibold relative">
                <div className="absolute bottom-0 w-full pr-3 pb-9">
                    <p className="text-2xl font-extrabold">American Tactical Gear, LLC</p>
                    <p>1201 North Market Street, Suite 111</p>
                    <p>Wilmington, Delaware, 19801</p>
                    <p>Phone : (302) 403-2422</p>
                    <p>Fax : (302) 403-2423</p>
                </div>
            </div>
        </div>
    );
}

export default Home;
