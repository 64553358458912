import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Helmet04 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-helmets04-m md:bg-helmets04 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[468px] md:h-[140px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">HELMETS</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-5/6">
                            <div>LEVEL IIIA BALLISTIC VISOR</div>
                            <div className="text-xl md:text-2xl">FOR TACTICAL HELMET</div>
                        </div>
                        <hr />
                        <div className="text-primary-yellow mt-3">
                            <div>BALLISTIC VISOR FOR BULLETPROOF HELMET</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            Ballistic Visor has a 4-way adjustable mounting system to bring the shield closer or further away from your face, as well as up and down.
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            Visor comes with adjustment system that makes it easy for you to switch between 3 different locking positions at any time on the fly!
                        </div>    
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            Straight ahead, halfway up, or straight over head? You decide how high and far your helmet should be pushed back by using this convenient pin adjustmentsystem right in front of you.
                        </div>    
                    </div>
                    <div className="flex flex-col md:flex-row md:ml-6">
                        <div className="mx-28 my-6 md:mx-6 md:mb-1">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets04-01.jpg')} alt="" draggable="false" />
                        </div>
                        <div className="mx-28 my-6 md:mx-6 md:mb-1">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets04-02.jpg')} alt="" draggable="false" />
                        </div>
                        <div className="mx-28 my-6 md:mx-6 md:mb-1">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets04-03.jpg')} alt="" draggable="false" />
                        </div>
                    </div>
                    <div className="mx-6 md:mx-12 text-xs font-semibold">
                        HELMET SOLD SEPARATELY
                    </div>

                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        TECH SPECS
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Protection</div>
                        <div className="w-11/12">
                            NIJ LEVEL IIIA RATED BALLISTIC VISOR FOR TACTICAL HELMET
                        </div>
                        <div className="font-bold">Fragments Protection</div>
                        <div className="w-11/12">
                            HIGH PROTECTION AGAINST FRAGMENTS (STANAG 2920 ED.2)
                        </div>
                        <div className="font-bold">Rail System</div>
                        <div className="w-11/12">
                            WILL WORK WITH ANY STANDARD RAIL SYSTEM
                        </div>
                        <div className="mt-5 font-bold">Material</div>
                        <div className="w-11/12">
                            22 MM THICK POLY-CARBONATE, WEIGHT ONLY 3 LBS / 1.35 KG
                        </div>
                    </div>


                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Helmet04;
