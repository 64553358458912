import { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";

function Bullet ({ setBg }) {
    useEffect(() => {
        setBg('bg-bullet-m md:bg-bullet md:bg-right-top');
    }, [setBg]);

    return (
        <div className="w-full flex flex-col">
            <div className="h-[380px] md:h-[100px]" />
            <div className="ml-6 md:ml-12 mb-10 font-goodtimes text-primary-yellow md:text-primary-dark-yellow text-left text-6xl md:text-8xl md:w-1/2">
                <div>BULLET PROOF GEARS</div>
            </div>
            <div className="mx-6 md:ml-12 mb-8 md:w-2/5">
                <svg viewBox="0 0 913.6 501.2">
                <g transform="matrix(1.4528 0 0 1.4528 -603.44 -447.29)">
                    <polygon stroke="#FFFFFF" strokeWidth="3.109" strokeMiterlimit="14.528" points="699.1,498.9 754.9,498.9 782.9,450.5 
                        754.9,402.1 699.1,402.1 671.1,450.5 	"/>
                    <path fill="#FFFFFF" d="M750.4,454.8h0.9c0.7,0,1.2,0.5,1.3,1.2c0,2,0,4,0,6c0,0.8-0.5,1.3-1.3,1.3h-0.9v1.8h0.9
                        c0.8,0,1.3,0.5,1.3,1.3c0,2,0,4,0,6c0,0.8-0.5,1.3-1.3,1.3h-0.9v3c0,0.1,0,0.3,0,0.4c-0.1,0.6-0.6,1-1.2,1h-0.4H705
                        c-0.9,0-1.4-0.5-1.4-1.4v-2.7v-0.3h-0.9c-0.8,0-1.3-0.5-1.3-1.2c0-2,0-4,0-6c0-0.7,0.5-1.2,1.3-1.2h0.9v-1.8h-0.8
                        c-0.8,0-1.3-0.5-1.3-1.3c0-2,0-4,0-6c0-0.8,0.5-1.2,1.3-1.3h0.9v-0.4c0-1,0-2,0-3c0-0.8,0.6-1.4,1.4-1.4c1.4,0.1,2.4-0.6,3.2-1.7
                        c0.8-1.1,1.3-2.4,1.6-3.8c0.5-2.4,0.6-4.9,0.5-7.3c-0.1-1.8-0.2-3.5-0.4-5.2c-0.2-1.6-0.5-3.2-0.7-4.8c-0.1-0.6,0.2-1.2,0.8-1.4
                        c2.7-0.9,5.5-1.8,8.2-2.8c0.9-0.3,1.7,0.3,1.7,1.3c0,1.4-0.1,2.8,0,4.1c0.1,2.3,0.4,4.6,1.6,6.7c1,1.8,2.5,2.9,4.6,3.1
                        c1.5,0.1,3,0.1,4.3-0.7c1.5-0.8,2.3-2.1,2.9-3.6c0.5-1.3,0.8-2.7,0.9-4c0.1-1.6,0.1-3.1,0.1-4.7c0-0.3,0-0.6-0.1-0.9
                        c0-1,0.8-1.5,1.7-1.2c2,0.7,4,1.3,6,2c0.6,0.2,1.2,0.4,1.8,0.6c0.8,0.2,1.3,0.7,1.1,1.8c-0.2,1.4-0.5,2.8-0.6,4.2
                        c-0.2,1.4-0.4,2.9-0.4,4.3c-0.1,1.7,0,3.5,0,5.2c0,1.6,0.3,3.2,0.8,4.8c0.4,1.1,0.9,2.2,1.7,3c0.8,0.8,1.7,1.1,2.8,1.1
                        c0.7,0,1.3,0.5,1.3,1.3v3.1L750.4,454.8L750.4,454.8z M705.6,465.1h4.3c0.8,0,1.3,0.5,1.3,1.4v5.8c0,0.9-0.5,1.4-1.4,1.4h-3.9h-0.3
                        v2.4h42.8v-2.4H748h-4c-0.8,0-1.3-0.5-1.3-1.3v-5.9c0-0.8,0.5-1.3,1.3-1.3h4h0.3v-1.8H748h-4c-0.8,0-1.3-0.5-1.3-1.3v-6
                        c0-0.1,0-0.2,0-0.3c0.2-0.6,0.6-0.9,1.3-0.9h4h0.3V452c-0.1,0-0.1,0-0.2,0c-0.9-0.1-1.7-0.4-2.4-0.9c-1.4-0.9-2.3-2.3-2.9-3.8
                        c-0.9-2.4-1.2-4.9-1.2-7.4c0-1.2,0-2.5,0.1-3.7c0-0.2-0.1-0.3-0.3-0.4c-1.2-0.4-2.3-0.8-3.4-1.1c-0.9-0.3-1.7-0.6-2.6-0.9
                        c-0.1,0.3-0.2,0.6-0.3,0.9c-0.7,1.8-1.8,3.4-3.5,4.4c-1.5,0.9-3.2,1.2-4.9,1.1c-1.5,0-2.9-0.4-4.2-1.1c-1.6-1-2.7-2.4-3.4-4.1
                        c-0.2-0.4-0.3-0.8-0.5-1.1c0,0-0.1,0-0.1,0c-2,0.7-4,1.3-6.1,2c-0.2,0-0.2,0.1-0.2,0.3c0,0.6,0.1,1.2,0.1,1.8c0,2,0,4-0.4,6
                        c-0.3,1.7-0.7,3.3-1.6,4.8c-0.9,1.5-2.1,2.7-3.9,3.1c-0.3,0.1-0.5,0.1-0.8,0.1v2.7h0.4h3.9c0.9,0,1.4,0.5,1.4,1.4v5.8
                        c0,0.9-0.5,1.4-1.4,1.4h-3.9h-0.3L705.6,465.1L705.6,465.1z M718.1,431.9c-0.4-2.2-0.5-4.4-0.4-6.6c-0.2,0-0.3,0.1-0.4,0.1
                        c-1.9,0.6-3.8,1.3-5.7,1.9c-0.3,0.1-0.4,0.2-0.3,0.5c0.2,1.2,0.4,2.5,0.5,3.8c0.1,0.8,0.2,1.5,0.3,2.3
                        C714.1,433.2,716.1,432.5,718.1,431.9L718.1,431.9z M741.9,433.8c0.1-0.8,0.2-1.6,0.3-2.3c0.2-1.3,0.3-2.5,0.5-3.8
                        c0-0.2,0-0.3-0.2-0.4c-1.6-0.5-3.1-1-4.7-1.6c-0.5-0.2-1-0.3-1.5-0.5c0.1,2.2,0,4.4-0.4,6.6C737.9,432.5,739.9,433.2,741.9,433.8
                        L741.9,433.8z M709.2,456.8h-5.8v4.5h5.8V456.8z M744.8,456.8v4.5h5.8v-4.5H744.8z M703.4,467.1v4.5h5.8v-4.5H703.4z M744.8,471.6
                        h5.8v-4.4h-5.8V471.6z"/>
                    <path fill="#FFFFFF" d="M727,460h-6c-0.6,0-1.1-0.4-1.1-1c0-0.6,0.5-1,1.1-1H733c0.6,0,1.1,0.4,1.1,1c0,0.6-0.5,1-1.1,1L727,460
                        L727,460z"/>
                    <path fill="#FFFFFF" d="M727,453.9h-6.1c-0.5,0-1-0.4-1-0.9c-0.1-0.5,0.2-1,0.8-1.1c0.1,0,0.2,0,0.3,0H733c0.6,0,1.1,0.4,1.1,1
                        c0,0.6-0.5,1-1.1,1H727L727,453.9z"/>
                    <path fill="#FFFFFF" d="M727,466.1h-6c-0.6,0-1.1-0.4-1.1-1c0-0.6,0.4-1,1.1-1H733c0.6,0,1.1,0.5,1.1,1c0,0.6-0.5,1-1.1,1
                        L727,466.1L727,466.1z"/>
                    <path fill="#FFFFFF" d="M727,472.2h-6c-0.6,0-1.1-0.4-1.1-1c0-0.6,0.5-1,1.1-1h11.5c0.3,0,0.6,0,0.9,0.1c0.5,0.1,0.8,0.6,0.7,1.1
                        c-0.1,0.5-0.5,0.9-1,0.9L727,472.2L727,472.2z"/>
                    <path fill="#FFFFFF" d="M727,445.8h6c0.6,0,1.1,0.4,1.1,1c0,0.6-0.5,1-1.1,1H721c-0.6,0-1.1-0.4-1.1-1c0-0.6,0.5-1,1.1-1L727,445.8
                        L727,445.8z"/>
                    <polygon stroke="#FFFFFF" strokeWidth="3.109" strokeMiterlimit="14.528" points="505.5,498.9 561.4,498.9 589.4,450.5 
                        561.4,402.1 505.5,402.1 477.6,450.5 	"/>
                    <path fill="none" stroke="#FFFFFF" strokeWidth="2.8039" strokeMiterlimit="14.528" d="M511.5,426.8c2.7-0.6,5.1-1.2,7.6-1.7
                        c0.4-0.1,0.8-0.1,1.1-0.2c2.4-0.8,4.3-0.8,6.5,1.4c4.4,4.4,11.8,3.7,16-1.2c0.8-0.9,1.5-1.1,2.6-0.8c3.6,0.9,7.3,1.6,11.1,2.5
                        c-0.8,2.1-1.5,4-2.2,6c-2,5.9-2.1,12.1-2.4,18.2c-0.4,7.5-0.8,15.1-1.2,22.6c-0.1,1.4-0.6,1.7-1.8,1.7c-9.9,0-19.7,0-29.6,0
                        c-1.3,0-1.7-0.5-1.8-1.8c-0.5-8.9-1.1-17.8-1.4-26.6C515.7,440,514.5,433.4,511.5,426.8L511.5,426.8z"/>
                    <path fill="none" stroke="#FFFFFF" strokeWidth="2.8039" strokeMiterlimit="14.528" d="M503.3,438.5c2.3-3.5,4.6-6.9,7.1-10.7
                        c0.4,1,0.7,1.7,0.9,2.5c1,3.8,2,7.6,3,11.5c0.4,1.6-0.9,2.4-1.7,3.4c-0.1,0.1-0.7,0.1-1-0.1C508.9,442.8,506.2,440.7,503.3,438.5
                        L503.3,438.5z"/>
                    <path fill="none" stroke="#FFFFFF" strokeWidth="2.8039" strokeMiterlimit="14.528" d="M557.5,427.8c2.5,3.8,4.7,7.1,7.1,10.7
                        c-2,1.5-3.9,3.1-5.8,4.6c-0.5,0.4-1.1,0.9-1.7,1.2c-0.6,0.4-1.7,1-1.8,0.8c-0.7-0.8-1.8-2-1.8-2.8
                        C554.2,437.3,555.7,432.7,557.5,427.8L557.5,427.8z"/>
                    <polygon stroke="#FFFFFF" strokeWidth="3.109" strokeMiterlimit="14.528" points="792.2,551.3 848,551.3 876,502.9 848,454.5 
                        792.2,454.5 764.2,502.9 	"/>
                    <path fill="none" stroke="#FFFFFF" strokeWidth="3.109" strokeMiterlimit="14.528" d="M797.7,496.2l8.7-20.1c0,0,0.7-2,3.2-2
                        h21.5c0,0,2.2,0.2,3.5,3.2s7.9,17.8,7.9,17.8v28.5l-5.2,6c0,0-9.2,1.7-17.1,2c-7.9,0.2-16.6-2-16.6-2l-6-5.2L797.7,496.2
                        L797.7,496.2z"/>
                    <polygon stroke="#FFFFFF" strokeWidth="3.109" strokeMiterlimit="14.528" points="601.8,445.4 657.6,445.4 685.6,397 
                        657.6,348.6 601.8,348.6 573.8,397 	"/>
                    <path fill="#FFFFFF" d="M645.3,414.1c-10.5,3-20.9,3-31.3,0v1v8.2c0,1.1-0.3,1.4-1.3,1.4H608c-1.1,0-1.4-0.3-1.4-1.4
                        c0-3.7,0-7.4,0-11c0-0.6-0.2-0.9-0.7-1.3c-1.1-0.8-2.2-1.8-3.1-2.9c-0.4-0.5-0.3-1.4-0.3-2.2c0.1-1,0.4-2,0.5-2.9
                        c0.3-3.4,0.9-6.8,2.1-10c0.9-2.4,1.3-4.9,1.9-7.3c1.8-6.7,5.8-11.6,12.2-14.3c6.4-2.7,13.1-2.8,19.6-0.4
                        c7.7,2.8,12.1,8.4,13.7,16.4c0.5,2.3,1.2,4.6,2,6.8c1.1,3.3,1.5,6.7,1.8,10.2c0,0.5,0.2,0.9,0.3,1.3c0.4,1.1,0.4,2.1-0.5,3
                        c-0.9,1-1.8,2.1-3.1,2.6c-0.2,0.1-0.4,0.5-0.4,0.9c0,3.7,0,7.4,0,11c0,1.3-0.2,1.5-1.5,1.5h-4.5c-1.1,0-1.4-0.3-1.4-1.3v-8.2V414.1
                        L645.3,414.1z M653.9,400.2c0.1,0,0.2-0.1,0.3-0.1c-0.5-1.8-0.9-3.6-1.4-5.3c-0.7-2.4-1.5-4.7-2-7.1c-1.3-6.6-4.9-11.6-11.1-14.4
                        c-5.4-2.4-11.1-2.6-16.8-1.2c-7.8,2-12.6,7.1-14.3,15c-0.3,1.4-0.6,2.9-1,4.3c-0.5,1.9-1.2,3.7-1.8,5.6c-0.2,0.5-0.2,1.1-0.3,1.6
                        c-0.1,0.5-0.2,1.1-0.2,1.6c0.3-0.2,0.4-0.4,0.5-0.6c0.7-1.8,2-3,3.7-3.7c0.6-0.2,1.1-0.5,1.7-0.8c9.1-3.5,18.5-4.5,28.1-2.5
                        c4,0.8,7.8,1.9,11.4,3.7c1.2,0.6,2.1,1.5,2.6,2.7C653.5,399.4,653.7,399.8,653.9,400.2L653.9,400.2z M613.9,406.1
                        c0,1.8,0,3.6,0,5.4c0,0.5,0.1,0.7,0.6,0.9c10,2.9,20.1,2.9,30.1,0c0.5-0.1,0.6-0.4,0.6-0.9c0-3.6,0-7.2,0-10.8
                        c0-0.5-0.2-0.8-0.7-0.9c-10-3.7-20-3.7-30,0c-0.5,0.2-0.7,0.5-0.7,1C614,402.6,613.9,404.4,613.9,406.1L613.9,406.1z M605.4,408.3
                        c1.2-2.4,2.4-4.8,3.6-7.1c0.4-0.8,1-1.4,1.8-1.7c12.6-5.7,25.1-5.7,37.7,0c0.8,0.4,1.4,0.9,1.8,1.7c1,2.1,2.1,4.2,3.2,6.4
                        c0.1,0.3,0.3,0.5,0.4,0.8c1.4-1.2,1.4-1.2,0.7-2.8c-0.7-1.3-1.5-2.6-1.9-4c-0.9-2.6-2.9-4-5.4-4.9c-8.4-3-17-4.1-25.8-2.5
                        c-4.2,0.8-8.3,1.8-12.1,3.9c-0.6,0.3-1.2,0.9-1.5,1.5c-1.1,2-2,4-3,6C604,407,604,407.1,605.4,408.3L605.4,408.3z M612.1,419.1V401
                        c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4,0.4-0.9,0.7-1.2,1.2c-0.7,1.2-1.3,2.5-1.9,3.8c-0.2,0.4-0.3,0.9-0.3,1.4c0,3.7,0,7.4,0,11
                        c0,0.3,0,0.6,0,0.9L612.1,419.1L612.1,419.1z M650.8,419.1c0-2.7-0.2-5.3,0-7.8c0.2-3.4-0.4-6.4-2.2-9.2c0,0,0-0.1,0-0.1
                        c-0.4-0.4-0.8-0.8-1.2-1.2l-0.2,0.2v18.1L650.8,419.1L650.8,419.1z M608.5,421v1.8h3.5V421H608.5z M650.7,422.8V421h-3.5v1.8H650.7
                        z"/>
                    <path fill="#FFFFFF" d="M612.1,389.1c0.3-5.2,2.8-9.1,7-12c1-0.7,2.1-1.2,3.2-1.8c0.9-0.5,1.4-0.4,1.7,0.2c0.3,0.6,0,1-0.9,1.4
                        c-2.5,1.1-4.6,2.7-6.3,4.8c-1.8,2.3-2.8,4.9-2.8,7.9c0,0.6-0.3,1-0.9,1c-0.7,0-1-0.4-0.9-1.1L612.1,389.1L612.1,389.1z"/>
                    <polygon stroke="#FFFFFF" strokeWidth="3.109" strokeMiterlimit="14.528" points="699.1,610.2 754.9,610.2 782.9,561.8 
                        754.9,513.4 699.1,513.4 671.1,561.8 	"/>
                    <path fill="#FFFFFF" d="M693.1,565.6c-1.5-5.6,1.4-9.4,5.4-13c4.9-4.3,10.6-6.5,16.9-7.6c10.1-1.7,20-1.3,29.8,1.6
                        c6.3,1.9,11,6,14.9,11.2c0.4,0.6,0.7,1.4,0.7,2.1c0,3.6,0.2,7.2-0.2,10.8c-0.1,1.2-1.2,2.6-2.2,3.2c-4.9,3-10.2,4.8-16,5.5
                        c-4.3,0.5-7.4-1.1-9.4-4.8c-0.8-1.4-1.3-3-1.9-4.4c-0.9-2-1.4-4.7-4.2-4.6c-2.7,0-3.2,2.6-4.1,4.6c-0.9,1.9-1.6,3.8-2.7,5.6
                        c-1.8,2.9-4.5,4.1-7.9,3.8c-6.2-0.5-11.8-2.7-17.2-5.7c-1.3-0.7-1.9-1.8-1.9-3.3C693.1,569,693.1,567.3,693.1,565.6L693.1,565.6z
                        M726.9,546.4l-0.1-0.4c-4.5,0.7-9.1,1.2-13.6,2.1c-5.2,1.1-10,3.1-13.5,7.4c-0.6,0.7-1,1.7-1,2.6c-0.1,3.3,0,6.5,0,9.8
                        c0,2.3,1.1,3.9,3.3,4.8c2.9,1.3,6,1.5,9.1,1.5c3.4,0,5.9-1.5,7.6-4.4c0.8-1.4,1.6-2.9,2.6-4.3c3.2-4.6,8.3-4.6,11.5,0
                        c1.1,1.5,1.9,3.2,2.9,4.7c1.3,2,2.9,3.5,5.3,3.8c3.9,0.6,7.7,0.3,11.3-1.3c2-0.9,3.1-2.3,3.1-4.5c0-3.3,0.2-6.6,0-9.8
                        c0-1-0.5-2.1-1.2-2.9c-2.4-3-5.6-4.8-9.2-6.1C739.1,547.2,733.1,546.4,726.9,546.4L726.9,546.4z"/>
                    <path fill="#FFFFFF" d="M766.8,574.5c-1.6-1.4-3.1-2.6-4.5-3.9c-0.3-0.3-0.5-0.8-0.5-1.2c0-3,0-6,0-9
                        C765.3,563.2,767.3,569,766.8,574.5L766.8,574.5z"/>
                    <path fill="#FFFFFF" d="M687.2,573.6c-0.1-5.2,1.4-9.6,5.1-13.4c0,3.3,0,6.4,0,9.5c0,0.3-0.3,0.7-0.6,0.9
                        C690.2,571.6,688.8,572.5,687.2,573.6L687.2,573.6z"/>
                    <polygon stroke="#FFFFFF" strokeWidth="3.109" strokeMiterlimit="14.528" points="603.1,551.3 659,551.3 687,502.9 659,454.5 
                        603.1,454.5 575.2,502.9 	"/>
                    <path fill="#FFFFFF" d="M648,530.3h-33.8c-0.2,0-0.3-0.1-0.5-0.1c-4.5-0.6-8.1-4.5-8.2-9.1c-0.1-2.1,0-4.2,0-6.3
                        c0-0.2,0-0.4-0.1-0.5c-0.9-0.7-1.4-1.7-1.6-2.8v-5.6c0.3-1.2,0.8-2.2,1.8-3c0.8-0.6,1.6-0.8,2.6-0.9v-0.7c0-2.9,0-5.9,0-8.8
                        c0-4.4,2.6-8,6.7-9.4c0.3-0.1,0.4-0.3,0.4-0.6c0-1.4,0-2.7,0-4.1c0-1.5,0.6-2.3,2-2.8c0,0,0.1-0.1,0.1-0.1h9c1,0.3,1.8,0.9,2.1,2
                        c0.1,0.3,0.1,0.7,0.1,1v4h5.3v-4c0-0.3,0-0.7,0.1-1c0.3-1.1,1-1.7,2.1-2h9c1.7,0.6,2.2,1.8,2.1,3.5c-0.1,1.2,0,2.4,0,3.5
                        c0,0.3,0.1,0.4,0.4,0.5c4.3,1.5,6.7,5,6.7,9.6v8.7v0.6c1.2,0,2.2,0.5,3,1.2c0.8,0.7,1.2,1.6,1.4,2.6v5.6c-0.3,1.1-0.7,2.1-1.6,2.8
                        c-0.1,0.1-0.1,0.3-0.1,0.5v5.5c0,4.5-2.5,8-6.6,9.5C649.4,530,648.7,530.1,648,530.3L648,530.3z M620.5,501.4
                        c0.8-0.1,1.5-0.3,2.2-0.3c4.7,0,9.3,0,14,0h0.6v1.8h-0.7h-14c-1.4,0-2.1,0.7-2.1,2.1c0,1.3,0.1,2.6,0,3.9c-0.2,1.6,0.6,2.5,1.8,3.3
                        c0.2,0.1,0.3,0.2,0.5,0.3c0.9,0.5,1.9,0.9,3,0.9c0-0.5,0-1,0-1.5c0-1.3,0.7-1.9,2-2h6.6c1.3,0,2,0.7,2,2v1.4c0.1,0,0.2,0,0.2,0
                        c2-0.3,3.6-1.2,4.9-2.7c0.1-0.1,0.1-0.3,0.1-0.4c0-1.8,0-3.7,0-5.5c0-1.1-0.7-1.8-1.8-1.8h-0.8V501c0.9,0.1,1.7,0.3,2.6,0.4
                        c0,0,0-0.1,0-0.2c0-2.8,0-5.6,0-8.5c-0.1-2.8-0.8-5.5-2-8.1c-0.1-0.3-0.3-0.4-0.6-0.4h-16c-0.3,0-0.5,0.1-0.6,0.4
                        c-0.5,1.4-1.1,2.8-1.5,4.2c-0.6,2.2-0.6,4.5-0.5,6.8L620.5,501.4L620.5,501.4z M620.5,513v2.6c0,4.6,1.3,8.7,3.8,12.5
                        c0.1,0.2,0.4,0.3,0.6,0.3c4.2,0,8.3,0,12.5,0c0.2,0,0.5-0.1,0.5-0.3c1.3-1.9,2.2-3.9,2.9-6.1c0.9-2.9,1-5.9,0.9-8.9
                        c0,0,0,0-0.1-0.1c-1.5,1.2-3.2,1.9-5.2,2.1v1.5c0,1.3-0.7,2-2,2h-6.5c-1.3,0-2-0.7-2-2v-1.5C623.8,515,622.1,514.3,620.5,513
                        L620.5,513z M616.3,484.5c-0.3,0.1-0.5,0.1-0.8,0.2c-3.4,1-5.7,4-5.7,7.6c0,3.1,0,6.1,0,9.2c0,0.1,0,0.3,0,0.5h3.5v-1.7
                        c0-0.5,0.2-1,0.6-1.4c0.2-0.2,0.3-0.4,0.3-0.6c0-0.5,0-1,0-1.6h1.8v1.7h2.6v-0.6v-4.1c0-2.8,0.5-5.6,1.5-8.3c0.4-1,0.9-1.9,1.3-3
                        c-0.7,0-1.4,0-2.1,0c-0.1,0-0.3,0.1-0.3,0.2c-1.6,2.8-2.5,5.9-2.9,9.1c-0.1,1-0.1,2-0.2,3h-1.7
                        C614.3,491.3,614.9,487.8,616.3,484.5L616.3,484.5z M615.2,507.3c0,0.2,0,0.3,0,0.4c0,3.1,0,6.2,0,9.3c0,4.1,1.2,7.8,3.7,11
                        c0.1,0.2,0.3,0.4,0.4,0.4c1,0,2,0,3,0c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.3-0.5-0.4-0.7c-1.9-3.4-3-7-3.1-10.9c-0.1-2.9,0-5.8-0.1-8.7
                        v-0.6L615.2,507.3L615.2,507.3z M639.8,528.5c1.1,0,2,0,3,0c0.1,0,0.3-0.1,0.4-0.2c2.2-2.9,3.6-6.1,3.7-9.8
                        c0.2-3.6,0.1-7.2,0.1-10.8c0-0.1,0-0.2,0-0.4h-3.5v0.7v7.2c0,4-0.8,7.8-2.6,11.4C640.5,527.2,640.2,527.8,639.8,528.5L639.8,528.5z
                        M645.9,484.5c0.1,0.3,0.2,0.5,0.2,0.7c0.9,2.4,1.6,4.9,1.7,7.5c0.1,1.9,0.1,3.7,0.1,5.6c0,0.2,0.1,0.5,0.2,0.6
                        c0.4,0.4,0.7,0.9,0.7,1.5v1.6h3.5c0-0.1,0-0.3,0-0.4v-9.5c0-1.3-0.3-2.5-1-3.7C650.1,486.3,648.3,485,645.9,484.5L645.9,484.5z
                        M648.9,517.9c-0.4,1.9-0.6,3.8-1.2,5.5c-0.6,1.8-1.5,3.4-2.2,5.1c0.4,0,1,0,1.5,0c4.3-0.1,7.5-2.9,8-7.1c0.2-1.7,0.1-3.3,0.1-5
                        v-1.2c-0.3,0-0.6,0.1-0.8,0.1c0,0.2,0,0.3,0,0.4c0,1.6-0.6,2.2-2.2,2.2H648.9L648.9,517.9z M607.2,515.2c0,0.2,0,0.2,0,0.4
                        c0,1.7,0,3.4,0,5.1c0.1,3.4,2.2,6.3,5.5,7.4c1.3,0.5,2.7,0.4,4.2,0.4c-1.1-1.6-2-3.3-2.5-5.1s-0.8-3.6-1.2-5.5c-1,0-2.2,0-3.3,0
                        c-1.1,0-1.8-0.7-1.8-1.8c0-0.3,0-0.5,0-0.8C607.8,515.2,607.5,515.2,607.2,515.2L607.2,515.2z M613.4,510.8v-7c-2,0-3.9-0.1-5.8,0
                        c-1.1,0-2,1.1-2.1,2.1c-0.1,1.8-0.1,3.5,0,5.3c0.1,1.2,1.4,2.3,2.5,2.1c0-0.1,0-0.2,0-0.3c0-1.7,0.6-2.3,2.3-2.3L613.4,510.8
                        L613.4,510.8z M654.1,513.4c1.5,0,2.6-1.1,2.6-2.8c0-1.4,0-2.7,0-4.1c0-1.8-1.1-2.9-2.9-2.9h-4.5c-0.2,0-0.3,0-0.5,0v7h0.6
                        c0.9,0,1.8,0,2.8,0c1.2,0,1.8,0.7,1.9,1.9L654.1,513.4L654.1,513.4z M635.5,482.5h3.2c0.4-1.4,0.8-1.7,2.3-1.7c0.4,0,0.9,0,1.3,0
                        c1.2-0.1,2,0.3,2.5,1.4c0.1,0.1,0.2,0.2,0.3,0.4c0.1-1.5,0.1-3,0.1-4.5c0-0.5-0.4-0.8-0.9-0.8c-2.6,0-5.2,0-7.8,0
                        c-0.6,0-1,0.3-1,0.9C635.5,479.6,635.5,481,635.5,482.5z M626.6,482.5c0-1.5,0-3,0-4.5c0-0.5-0.4-0.8-1-0.8c-2.5,0-5.1,0-7.7,0
                        c-0.7,0-1,0.3-1,1.1v3.9c0,0.1,0,0.2,0,0.3c0,0,0.1,0,0.1,0c0.1-0.2,0.3-0.4,0.4-0.6c0.4-0.8,1-1.2,2-1.2c0.5,0,1.1,0,1.6,0
                        c1.5,0,1.9,0.3,2.3,1.7L626.6,482.5L626.6,482.5z M646.1,498.4c-0.1-2.1,0-4.3-0.2-6.4c-0.2-3.3-1.3-6.4-2.9-9.3
                        c-0.1-0.1-0.2-0.3-0.3-0.3c-0.7,0-1.4,0-2.1,0v0.2c0.1,0.2,0.2,0.4,0.3,0.6c1.3,2.4,2.1,5,2.3,7.8c0.2,2.2,0.1,4.5,0.2,6.8
                        c0,0.2,0,0.4,0,0.6L646.1,498.4L646.1,498.4z M634.6,517v-5.2h-7v5.2H634.6z M618.6,505.5v-5.3h-3.5v5.3H618.6z M647,505.5v-5.2
                        h-3.5v5.2H647z M609.9,516.1h3.5v-3.5h-3.5V516.1z M648.8,512.6v3.5h3.5v-3.5H648.8z"/>
                    <path fill="#FFFFFF" d="M623.2,486.1H639v12.3h-15.8V486.1z M637.2,487.9h-12.3v8.7h12.3V487.9z"/>
                    <path fill="#FFFFFF" d="M626.7,491.4v-1.7h8.8v1.7H626.7z"/>
                    <path fill="#FFFFFF" d="M626.7,494.9v-1.7h5.2v1.7H626.7z"/>
                    <path fill="#FFFFFF" d="M633.8,494.9v-1.7h1.7v1.7H633.8z"/>
                </g>
                <g transform="matrix(1.4528 0 0 1.4528 -603.44 -447.29)">
                    <rect x="697.5" y="362.5" fill="#F5F5D8" width="111.9" height="34.3"/>
                    
                        <text transform="matrix(0.6883 0 0 0.6883 708.3097 386.5397)" fill="#4D4927" fontFamily="'Good Times'" fontSize="28.9252px">VESTS</text>
                </g>
                <g transform="matrix(1.4528 0 0 1.4528 -603.44 -447.29)">
                    <rect x="792.6" y="560.9" fill="#F5F5D8" width="251.6" height="34.3"/>
                    
                        <text transform="matrix(0.6883 0 0 0.6883 803.3998 584.9399)" fill="#4D4927" fontFamily="'Good Times'" fontSize="28.9252px" letterSpacing="-1">B</text>
                    
                        <text transform="matrix(0.6883 0 0 0.6883 820.1099 584.9399)" fill="#4D4927" fontFamily="'Good Times'" fontSize="28.9252px">ALLISTIC PL</text>
                    
                        <text transform="matrix(0.6883 0 0 0.6883 966.6497 584.9399)" fill="#4D4927" fontFamily="'Good Times'" fontSize="28.9252px" letterSpacing="-3">A</text>
                    
                        <text transform="matrix(0.6883 0 0 0.6883 983.9496 584.9399)" fill="#4D4927" fontFamily="'Good Times'" fontSize="28.9252px">TES</text>
                </g>
                <g transform="matrix(1.4528 0 0 1.4528 -603.44 -447.29)">
                    <rect x="415.4" y="511.1" fill="#F5F5D8" width="141.6" height="34.3"/>
                    
                        <text transform="matrix(0.6883 0 0 0.6883 426.1696 535.1597)" fill="#4D4927" fontFamily="'Good Times'" fontSize="28.9252px">APPAREL</text>
                </g>
                <g transform="matrix(1.4528 0 0 1.4528 -603.44 -447.29)">
                    <rect x="518.5" y="307.9" fill="#F5F5D8" width="137.8" height="34.3"/>
                    
                        <text transform="matrix(0.6883 0 0 0.6883 529.2894 331.92)" fill="#4D4927" fontFamily="'Good Times'" fontSize="28.9252px">HELMETS</text>
                </g>
                <g transform="matrix(1.4528 0 0 1.4528 -603.44 -447.29)">
                    <rect x="673.4" y="618.6" fill="#F5F5D8" width="147.6" height="34.3"/>
                    
                        <text transform="matrix(0.6883 0 0 0.6883 684.95 642.6299)" fill="#4D4927" fontFamily="'Good Times'" fontSize="28.9252px">GOGGLES</text>
                </g>
                <g transform="matrix(1.4528 0 0 1.4528 -603.44 -447.29)">
                    <rect x="560.5" y="561.4" fill="#F5F5D8" width="96.4" height="34.3"/>
                    
                        <text transform="matrix(0.6883 0 0 0.6883 572.0198 585.4599)" fill="#4D4927" fontFamily="'Good Times'" fontSize="28.9252px" letterSpacing="-1">B</text>
                    
                        <text transform="matrix(0.6883 0 0 0.6883 588.7295 585.4599)" fill="#4D4927" fontFamily="'Good Times'" fontSize="28.9252px" letterSpacing="-2">A</text>
                    
                        <text transform="matrix(0.6883 0 0 0.6883 606.6898 585.4599)" fill="#4D4927" fontFamily="'Good Times'" fontSize="28.9252px">GS</text>
                </g>
                </svg>
            </div>

            <div className="flex flex-col mb-12 md:mx-9">
                <div className="flex flex-col md:flex-row">
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/vests01">
                            <img className="h-auto w-[200%]" src={require('./assets/02-01-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-vests bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/vests02">
                            <img className="h-auto w-[200%]" src={require('./assets/02-02-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-vests bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/bplates01">
                            <img className="h-auto w-[200%]" src={require('./assets/02-03-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-ballistic bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                </div>

                <div className="flex flex-col md:flex-row">
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/bplates02">
                            <img className="h-auto w-[200%]" src={require('./assets/02-04-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-ballistic bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/apparel01">
                            <img className="h-auto w-[200%]" src={require('./assets/02-05-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-apparel bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/apparel02">
                            <img className="h-auto w-[200%]" src={require('./assets/02-06-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-apparel bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                </div>

                <div className="flex flex-col md:flex-row">
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/apparel03">
                            <img className="h-auto w-[200%]" src={require('./assets/02-07-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-apparel bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/apparel04">
                            <img className="h-auto w-[200%]" src={require('./assets/02-08-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-apparel bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/apparel05">
                            <img className="h-auto w-[200%]" src={require('./assets/02-09-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-apparel bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                </div>

                <div className="flex flex-col md:flex-row">
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/apparel06">
                            <img className="h-auto w-[200%]" src={require('./assets/02-10-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-apparel bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/apparel07">
                            <img className="h-auto w-[200%]" src={require('./assets/02-11-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-apparel bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/helmet01">
                            <img className="h-auto w-[200%]" src={require('./assets/02-12-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-helmets bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                </div>

                <div className="flex flex-col md:flex-row">
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/helmet02">
                            <img className="h-auto w-[200%]" src={require('./assets/02-13-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-helmets bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/helmet03">
                            <img className="h-auto w-[200%]" src={require('./assets/02-14-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-helmets bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/helmet04">
                            <img className="h-auto w-[200%]" src={require('./assets/02-15-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[130px] md:w-[130px] h-full bg-helmets bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                </div>

                <div className="flex flex-col md:flex-row">
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/goggle01">
                            <img className="h-auto w-[200%]" src={require('./assets/02-16-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-goggles bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/goggle02">
                            <img className="h-auto w-[200%]" src={require('./assets/02-17-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-goggles bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/goggle03">
                            <img className="h-auto w-[200%]" src={require('./assets/02-18-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-goggles bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                </div>

                <div className="flex flex-col md:flex-row">
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/goggle04">
                            <img className="h-auto w-[200%]" src={require('./assets/02-19-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-goggles bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/bag01">
                            <img className="h-auto w-[200%]" src={require('./assets/02-20-bullet.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-bags bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <div className="mx-6 my-3 md:mx-3 relative grow md:block hidden">
                        <img className="h-auto w-[200%]" src={require('./assets/02-00-bullet.jpg')} alt="" />
                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center"><Logo width="130"/></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bullet;
