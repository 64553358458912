import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Apparel03 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-apparel03-m md:bg-apparel03 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px] md:h-[250px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">APPAREL</span>
                        <div className="font-goodtimes text-4xl mt-5 md:text-5xl text-primary-dark-yellow md:text-primary-yellow w-11/12">
                            <div>ATG ARMORED HOODIE DEFENDER 2</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="mt-5 mx-6 md:mx-12">
                        <img className="h-auto w-[200%]" src={require('./assets/02-apparel03-01.jpg')} alt="" draggable="false" />
                    </div>

                    <div className="mx-6 md:ml-12">
                        <div className="mt-5 font-bold">Invest in discreet protection for your everyday wear</div>
                        <div className="w-11/12">
                            The hood has removable IIIA panels that can instantly be pulled up when you need it, and down or removed when you don't. Detachable, overlapping panels are secured via medical grade elastic and velcro around the torso portion to provide ballistic protection regardless, whether or not the hoodie is zipped up.
                        </div>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Removable, discreet head protection for when you need it
                            </li>
                            <li>
                                Slash-resistant
                            </li>
                            <li>
                                Velcro straps around shoulders and waist for easy fit
                            </li>
                            <li>
                                Detachable ballistic vest for cleaning and maintenance
                            </li>
                            <li>
                                Unisex style and sizing we recommend women buy one or two sizes down
                            </li>
                            <li>
                                Hoodie - 50% Polyester / 50% Cotton Fleece
                            </li>
                            <li>
                                Detachable waterproof Kevlar panel vest for easy cleaning (hood armor is not detachable)
                            </li>
                            <li>
                                Removable head protection
                            </li>
                            <li>
                                Slash-resistant
                            </li>
                            <li>
                                Weight: 6-9 lbs. depending on the size
                            </li>
                        </ol>
                    </div>

                    <div className="flex flex-col md:flex-row md:ml-6 mt-5">
                        <div className="mx-20 md:mx-5 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel03-02.jpg')} alt="" draggable="false" />
                        </div>
                        <div className="mx-20 md:mx-5 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel03-03.jpg')} alt="" draggable="false" />
                        </div>
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Apparel03;
