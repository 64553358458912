import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Vests01 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-vest01-m md:bg-vest01 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg,toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px] md:h-[250px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">VESTS</span>
                        <div className="font-goodtimes text-4xl mt-5 md:text-5xl text-primary-yellow w-2/3">
                            <div>ATG RIFLE PLATE CARRIER</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl mt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Compatible with up to 10” x 12” Level 3 and 4 ESAPI, as well as similar plates and plate backers
                            </li>
                            <li>
                                Made of 500 denier Cordura nylon
                            </li>
                            <li>
                                Empty weight: 2 lbs 5 oz.
                            </li>
                            <li>
                                Cummerbund sides accept 17” x 6” soft armor
                            </li>
                            <li>
                                Numerous PALS/MOLLE webbing attachment points along the front, back and shoulders
                            </li>
                            <li>
                                Detachable cummerbund with internal and external PALS webbing for side plates
                            </li>
                            <li>
                                Padded, fully adjustable front, back and shoulder areas
                            </li>
                            <li>
                                Bar-tacks reinforce high stress areas
                            </li>
                            <li>
                                Includes lightweight shoulder pads
                            </li>
                            <li>
                                Two loop areas for identifiers
                            </li>
                            <li>
                                Antenna, wire or hydration hose guides
                            </li>
                            <li>
                                Robust drag handle
                            </li>
                            <li>
                                Quick release buckle secondary securing system
                            </li>
                            <li>
                                Berry compliant
                            </li>
                        </ol>
                        <div className="ml-2">MADE IN USA</div>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mx-6 md:ml-12 mt-5">
                        <div className="w-11/12">
                            ATG Rifle Plate Carrier is made of 500 denier Cordura nylon that's reinforced with bar-tacks in key stress areas. It also features a removable cummerbund for attaching pouches or side plates for additional protection. If the cummerbund isn't necessary, a secondary securing system with nylon straps and quick release buckles lets you easily remove it.
                        </div>
                        <div className="w-11/12 mt-5">
                            For gear storage, PALs webbing covers the back, front and shoulders for accessory attachment, while a hook and loop pocket on the front holds smaller items. For additional utility, this carrier has guides for wires, antennas or hydration hoses. With lightweight shoulder pads and a reinforced drag handle, this plate carrier keeps you protected and comfortable throughout your mission.
                        </div>
                        <div className="mt-5">
                            <img className="h-auto w-[200%]" src={require('./assets/02-vest01-01.png')} alt="" draggable="false" />
                        </div>
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Vests01;
