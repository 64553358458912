import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Goggle02 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-goggle02-m md:bg-goggle02 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[220px] md:h-[300px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">GOGGLES</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-dark-yellow md:text-primary-yellow w-11/12">
                            <div>WILEY X SPEAR (APEL)</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3 md:pb-8">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Coyote tan authorized for wear with Operational Camouflage Pattern (OCP) uniform
                            </li>
                            <li>
                                Authorized Protective Eyewear List (APEL) approved
                            </li>
                            <li>
                                90-degree wrap-back frame eliminates tunnel vision
                            </li>
                            <li>
                                Swivel hinges for compatibility with a helmet
                            </li>
                            <li>
                                Tri-Glide strap system provides a precise, comfortable fit
                            </li>
                            <li>
                                Heat-resistant frame and straps
                            </li>
                            <li>
                                2.5 mm lenses offer 100% protection from harmful UV rays
                            </li>
                            <li>
                                Foil anti-fog coating keeps the lenses from fogging
                            </li>
                            <li>
                                Symmetrical venting system lets air escape, minimizing fog
                            </li>
                            <li>
                                Anti-scratch T-Shell coating
                            </li>
                            <li>
                                Shatterproof Selenite lenses meet MIL-PRF-32432 (GL) ballistic standards
                            </li>
                            <li>
                                Removable foam gasket
                            </li>
                            <li>
                                Prescription insert is adaptable to the Talon, Patriot and G-Eye
                            </li>
                            <li>
                                Hard case is included with NSN or GSA approved items
                            </li>
                            <li>
                                Interchangeable lens capabilities
                            </li>
                            <li>
                                Low-profile, multi-functional design
                            </li>
                            <li>
                                Meets ballistic MIL-V-43511C standards
                            </li>
                            <li>
                                Night Vision Goggles (NVG) compatible
                            </li>
                            <li>
                                Includes the following:
                            </li>
                            <li>
                                Smoke Grey Lens
                            </li>
                            <li>
                                Clear Lens
                            </li>
                            <li>
                                Protective Storage Case
                            </li>
                            <li>
                                Protective Sleeve
                            </li>
                            <li>
                                Anti-Fog Wipe
                            </li>
                            <li>
                                Microfiber Cleaning Cloth
                            </li>
                            <li>
                                Instruction Card
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            With a low-profile design and powerful, innovative features, Wiley X Spear goggles offer high performance in any tactical operation. The frame features a 90-degree wrap-back to eliminate tunnel vision, while the heatresistant straps use a Tri-Glide system to adjust to your head for a comfortable fit.
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            A top down ventilation system minimizes fogging, and shatterproof Selenite polycarbonate lenses meet ballistic safety standards and block damaging UV rays.
                        </div>    
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            These goggles received U.S. Army APEL approval, proving they make them strong enough to hold up in the toughest conditions. Compatible with or without a helmet, these goggles are built to take on any challenge.
                        </div>    
                    </div>
                    <div className="mt-6 mx-8 md:mx-20">
                        <img className="h-auto w-[200%]" src={require('./assets/02-goggle02-01.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Goggle02;
