import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Baton02 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-baton02-m md:bg-baton02 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[260px] md:h-[300px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">BATON</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-11/12 md:w-5/6">
                            <div>ATG PUSH BATON</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Retracted Length: 8.25"
                            </li>
                            <li>
                                Expanded Length: 19.68"
                            </li>
                            <li>
                                Weight (Steel): 18.3 oz.
                            </li>
                            <li>
                                Weight (Airweight): 11.2 oz.
                            </li>
                            <li>
                                Airweight is constructed from aluminum tubing and a steel striking surface for a lighter feel
                            </li>
                            <li>
                                K Coat is constructed from 4130 Steel
                            </li>
                            <li>
                                Diameter: 1.06”
                            </li>
                            <li>
                                Disc Loc locking system
                            </li>
                            <li>
                                Push button retraction
                            </li>
                            <li>
                                Retention grooved button release is machined into the cap</li>
                            <li>
                                Durable foam handle
                            </li>
                        </ol>
                    </div>
                    <div className="mt-6 mb-8 mx-8 md:mx-20">
                        <img className="h-auto w-[200%]" src={require('./assets/04-baton02-01.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-6 mb-8 mx-8 md:mx-20">
                        <img className="h-auto w-[200%]" src={require('./assets/04-baton02-02.jpg')} alt="" draggable="false" />
                    </div>
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            Elegantly simple, yet incredibly strong, the ATG Push Baton is easily carried and rapidly deployed. This baton's revolutionary design uses two opposing discs that cam into an internal groove that is machined into each tube. Lockout is simple, stable and secure.
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            In this baton you will find the premium materials, exceptional workmanship and the flawless function of ATG all combined in a push button design with minimal parts. This is a baton with quality,durability and performance in every detail. This is the next generation of intermediate force.
                        </div>    
                    </div>
                    <div className="mt-6 mb-8 mx-32 md:mx-56">
                        <img className="h-auto w-[200%]" src={require('./assets/04-baton02-03.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Baton02;
