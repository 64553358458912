import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Modular ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-modular-m md:bg-modular md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px]" />
                    <div className="md:ml-12 mx-6 mb-12">
                        <div className="font-goodtimes text-4xl md:text-5xl text-primary-yellow w-2/3 md:w-full">
                            <div>MODULAR VEST FOR TACTICAL OPERATION</div>
                        </div>
                        <div className="font-goodtimes text-primary-yellow text-xl md:text-3xl">
                            <div>ANTI STAB</div>
                        </div>
                        <div className="font-inter text-primary-yellow text-sm md:text-base w-4/5">
                            <div>LEVEL IIIA + STAB1 BULLETPROOF VEST MULTI-THREAT PROTECTION</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3 mb-5">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-[#93B9B8] mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2 font-bold">
                            <li>
                                FABRIC: <span className="font-normal">Outer ballistic-type 500D nylon fabric for durability and colorfastness to resist fading, tears, and abrasions.</span>
                            </li>
                            <li>
                                MOLLE <span className="font-normal">webbing at all hard points; front, back, and sides for mounting MOLLEPouches, IFAK’s, etc.</span>
                            </li>
                            <li>
                                SIDE PROTECTION: <span className="font-normal">Extended sides for crucial ballistic overlap protection.</span>
                            </li>
                            <li>
                                UP-ARMOR: <span className="font-normal">Front and Back pockets for 10×12″ Hard Armor insert upgrade.</span>
                            </li>
                            <li>
                                SIDE ARMOR: <span className="font-normal">Left and Right side cummerbund side insert pockets for 6×6 or 6×8 size armor.</span>
                            </li>
                            <li>
                                MODULAR: <span className="font-normal">Includes: Front and Back Panels, removable collar, throat, biceps, and groin protection in Level IIIA soft armor panels.</span>
                            </li>
                            <li>
                                ADJUSTABLE: <span className="font-normal">Industrial grade hook-and-loop fasteners for critical torso and girth adjustments for all body-types.</span>
                            </li>
                            <li>
                                DESIGN: <span className="font-normal">Vest designed longer than other common short vests -that expose lower abdomen- without choking the wearer when seated.</span>
                            </li>
                        </ol>
                    </div>
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        TECH SPECS
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="font-bold">Design</div>
                        <div>MODULAR SPECIAL OPERATIONS VEST - DESIGNED FOR SWAT TEAMS</div>
                    </div>
                    <div className="mx-6 md:ml-12 pt-5">
                        <div className="font-bold">8 Panels</div>
                        <div>8PCS NIJ LEVEL IIIA SOFT ARMOR PANELS INCLUDED: FRONT, BACK, SIDE, GROIN, SHOULDER, THROAT, NECK</div>
                    </div>
                    <div className="mx-6 md:ml-12 pt-5">
                        <div className="font-bold">Build</div>
                        <div>ACCOMMODATES HARD ARMOR PLATES: FRONT AND BACK 10x12″ AND SIDE ARMOR (UP TO 6x8″)</div>
                    </div>
                    <div className="mx-6 md:ml-12 pt-5">
                        <div className="font-bold">Level 3A Protection</div>
                        <div>9mm FMJ RN, 9mm Luger or NATO, .40 S&W, .357 Magnum, .357 Sig, .45 Gap, .44 Magnum</div>
                    </div>
                    <div className="mx-6 md:ml-12 font-bold pt-5">BALLISTIC PANELS MADE IN USA</div>
                </div>

                <div className="pt-5 md:w-1/3 md:mb-8">
                    <div className="text-black font-acier text-4xl mx-6 md:ml-12">
                        COLORS
                    </div>
                    <div className="mx-6 md:flex md:justify-center">
                        <table className="w-full md:w-2/5 bg-[#ececec]">
                            <tbody>
                                <tr>
                                    <td className="mt-5">
                                        <img className="h-auto w-[100%] mt-8" src={require('./assets/01-modular01.jpg')} alt="" draggable="false" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img className="h-auto w-[100%] mt-8" src={require('./assets/01-modular02.jpg')} alt="" draggable="false" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center font-medium">OD GREEN</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img className="h-auto w-[100%] mt-8" src={require('./assets/01-modular03.jpg')} alt="" draggable="false" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center font-medium">BLACK</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Modular;
