import { Fragment, useState } from "react";
import { BsEnvelopeAt } from 'react-icons/bs';
import { isEmail, isEmpty, isMobilePhone } from 'validator';

function ContactForm () {
    const [isSubmit, setIsSubmit] = useState(false);
    const [submitOk, setSubmitOk] = useState(false);
    const [submitEr, setSubmitEr] = useState(false);
    const [cust, setCust] = useState({fname: '', lname: '', email: '', mobile: '', msg: ''});

    const handleSubmit = () => {
        setSubmitEr(false);
        setSubmitOk(false);
        setIsSubmit(true);
        let formBody = [];
        for (let prop in cust) {
            let encodedKey = encodeURIComponent(prop);
            let encodedValue = encodeURIComponent(cust[prop]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        fetch('https://atg-gear.com/sendInquiry.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: formBody
        }).then(response => {
            setCust({fname: '', lname: '', email: '', mobile: '', msg: ''});
            setIsSubmit(false);
            setSubmitOk(true);
            setTimeout(function () {
                setSubmitOk(false);
            }, 2000);
        }).catch(error => {
            setIsSubmit(false);
            setSubmitEr(true);
        });
    }

    return (
        <Fragment>
            <div>
                <div className="flex flex-row">
                    <div className="text-[#808080] font-acier text-4xl pt-5 flex flex-row mr-auto">
                        CONTACT US<span>&nbsp;</span><BsEnvelopeAt />
                    </div>
                    <div className={`pt-5 text-lg ${isSubmit ? "text-black" : (submitOk ? "text-green-600" : (submitEr ? "text-red-600" : "text-black"))}`}>
                        {isSubmit ? "Submitting.." : (submitOk ? "Submitted" : (submitEr ? "*Error submission" : ""))}
                    </div>
                </div>
                <div className="mt-[-8px] font-semibold">directly for further information</div>
                <fieldset disabled={isSubmit} >
                    <div className="flex flex-row pt-4">
                        <div className="w-1/2 pr-5">
                            <div>First Name*</div>
                            <div>
                                <input type="text" className="p-1 border-2 border-gray-400 bg-[#cccccc] w-full" placeholder="First Name" value={cust.fname} onChange={e => setCust({ ...cust, fname: e.target.value})} />
                            </div>
                            <div>Last Name*</div>
                            <div>
                                <input type="text" className="p-1 border-2 border-gray-400 bg-[#cccccc] w-full" placeholder="Last Name" value={cust.lname} onChange={e => setCust({ ...cust, lname: e.target.value})} />
                            </div>
                        </div>
                        <div className="w-1/2">
                            <div>Email*</div>
                            <div>
                                <input type="text" className={`p-1 border-2 bg-[#cccccc] w-full ${isEmail(cust.email) || isEmpty(cust.email) ? 'border-gray-400' : 'border-red-600 border-2'}`} placeholder="Email" 
                                    value={cust.email} onChange={e => setCust({ ...cust, email: e.target.value})} />
                            </div>
                            <div>Mobile Phone*</div>
                            <div>
                                <input type="text" className={`p-1 border-2 bg-[#cccccc] w-full ${isMobilePhone(cust.mobile) || isEmpty(cust.mobile) ? 'border-gray-400' : 'border-red-600 border-2'}`} placeholder="Mobile Phone" 
                                    value={cust.mobile} onChange={e => setCust({ ...cust, mobile: e.target.value})} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>Message</div>
                        <textarea rows="6" className="p-1 border-2 border-gray-400 bg-[#cccccc] mb-3 w-full resize-none" placeholder="Message"
                            value={cust.msg} onChange={e => setCust({ ...cust, msg: e.target.value})}/>
                    </div>
                    <div className="text-center"  >
                        <button className="px-5 py-1 border-2 border-gray-400 bg-[#cccccc] disabled:text-gray-400 disabled:border-gray-200" 
                            onClick={handleSubmit} disabled={isEmpty(cust.fname) || isEmpty(cust.lname) || isEmpty(cust.email) || isEmpty(cust.mobile) || !isEmail(cust.email) || !isMobilePhone(cust.mobile)}>SUBMIT</button>
                    </div>
                </fieldset>
            </div>
        </Fragment>
    );
}

export default ContactForm;