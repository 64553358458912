import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function BPlates02 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-bplates02-m md:bg-bplates02 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px] md:h-[250px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">BALLISTIC PLATES</span>
                        <div className="font-goodtimes text-4xl mt-5 md:text-5xl text-primary-yellow w-4/5 md:w-full">
                            <div>ATG V2 PLATE BUILD UP COAT</div>
                            <div className="text-xl md:text-2xl">10" X 12"</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl mt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Build-Up Coat (9.5 lbs, 0.56” thick)
                            </li>
                            <li>
                                Dimensions: 10” x 12”
                            </li>
                            <li>
                                Protection: 5.56x45 M193, 5.56x45 M855/SS109 at 3,100 FPS (+/- 30FPS), 7.62x51 M80 Ball (ie .308) at 3,000 FPS (+/- 30FPS), and DEA Certified
                            </li>
                            <li>
                                Capability: Edge-to-edge multi-hit protection
                            </li>
                            <li>
                                Form: Curved
                            </li>
                        </ol>
                    </div>
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        TECH SPECS
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Materials</div>
                        <div className="w-4/5">
                            Ballistic steel and alloy blend of 650 hardness on the Brinell scale, with FragLock Coating
                        </div>
                    </div>
                    <div className="mt-6 w-3/5 md:w-2/5 mx-auto mb-8">
                        <img className="h-auto w-[200%]" src={require('./assets/02-bplates02-01.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            The V2 body armor is able to stop Level III+ threats such as 7.62x39 / 7.62x51 / 5.56 M193s, 5.56 M855 penetrators, and .223/.308 WIN rounds. And unlike ceramic or polyethylene, this custom steel alloy blend is able to take several hits from these rounds without fail. This multi-hit special threat resistance is the distinguishing quality of the V2 plate.
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            The plate is curved, shooters cut and features our FragLock Coat, which we apply for two reasons: It helps reduce the dangers of spalling (when broken pieces of steel come off the plate) and fragmentation (when a bullet's remains bounce off the plate), protecting you from the aftermath of a bullet's impact once it has been stopped.
                        </div>    
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            The coat gives our plates a maintenance-free shelf life of twenty years, which makes this steel plate last much longer than polyethylene or ceramic alternatives. The Base Coat, which all our plates come with by default, is lighter and thinner with less protection, while the Build-Up Coat can be added for greater protection but also slightly more weight.
                        </div>    
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default BPlates02;
