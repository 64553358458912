import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Goggle03 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-goggle03-m md:bg-goggle03 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[210px] md:h-[300px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">GOGGLES</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-dark-yellow md:text-primary-yellow w-11/12">
                            <div>WILEY X SG-1</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3 md:pb-8">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                APEL approved
                            </li>
                            <li>
                                New Top Down ventilation system
                            </li>
                            <li>
                                Authorized for use by the U.S. and Danish military
                            </li>
                            <li>
                                Low profile, light weight, VNG compatible
                            </li>
                            <li>
                                First modular system created
                            </li>
                            <li>
                                NSN or GSA approved items come with hard case
                            </li>
                            <li>
                                Meets VO Ballistic MIL-PRF-31013 standards
                            </li>
                            <li>
                                Meets ANSI Z87.1-2003+ High Velocity safety standards
                            </li>
                        </ol>
                    </div>
                    <div className="mt-6 mx-8 md:mx-20">
                        <img className="h-auto w-[200%]" src={require('./assets/02-goggle03-01.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-6 mx-8 md:mx-20">
                        <img className="h-auto w-[200%]" src={require('./assets/02-goggle03-02.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Goggle03;
