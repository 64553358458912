import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Helmet03 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-helmets03-m md:bg-helmets03 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[435px] md:h-[140px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">HELMETS</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-5/6">
                            <div>ANTI-RIOT BALLISTIC VISOR</div>
                            <div className="text-xl md:text-2xl">FOR TACTICAL HELMET</div>
                        </div>
                        <hr />
                        <div className="text-primary-yellow mt-3">
                            <div>
                                THE HIGH-POWERED LENS OF THE TACTICAL HELMET VISOR STOPS .22 AGULA AND FRAGMENTS UP TO 850FT/S.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            With the weight of a mere 1.70 pounds, this sleek and stylish visor is made out of an unbreakable poly-carbonate material that provides protection against bullets and fragments:
                            <ul className="list-disc pl-5">
                                <li>
                                    Stops .22 LR Aguila 40 gr
                                </li>
                                <li>
                                    Protection against shrapnel acc. to STANAG 2920 - V50= 850ft/s
                                </li>
                            </ul>
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            It has been rigorously tested for military standards in accordance with STANAG 2920 stringent testing requirements, ensuring it can withstand high levels on impact without any damage to its structural integrity.
                        </div>    
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            It can be used by soldiers in combat, military personnel who need to worry about stray fire or shrapnel injuries while on the battlefield, police officers when they're out making arrests or even demonstrators protesting a rally.
                        </div>    
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        TECH SPECS
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Protection</div>
                        <div className="w-11/12">
                            STOPS .22 AGULA
                        </div>
                        <div className="font-bold">Fragments Protection</div>
                        <div className="w-11/12">
                            HIGH PROTECTION AGAINST FRAGMENTS (STANAG 2920 ED.2)
                        </div>
                        <div className="font-bold">Rail System</div>
                        <div className="w-11/12">
                            WILL WORK WITH ANY STANDARD RAIL SYSTEM
                        </div>
                        <div className="mt-5 font-bold">Material</div>
                        <div className="w-11/12">
                            6MM THICK POLY-CARBONATE,WEIGHT ONLY 1.7 LBS / 0.8KG STANDARD RAIL SYSTEM
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row md:ml-6">
                        <div className="mx-28 my-6 md:mx-6 md:mb-1">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets03-01.jpg')} alt="" draggable="false" />
                        </div>
                        <div className="mx-28 my-6 md:mx-6 md:mb-1">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets03-02.jpg')} alt="" draggable="false" />
                        </div>
                        <div className="mx-28 my-6 md:mx-6 md:mb-1">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets03-03.jpg')} alt="" draggable="false" />
                        </div>
                    </div>
                    <div className="mx-6 md:mx-12 text-xs font-semibold">
                        HELMET SOLD SEPARATELY
                    </div>

                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Helmet03;
