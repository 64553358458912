import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Vests02 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-vest02-m md:bg-vest02 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px] md:h-[250px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">VESTS</span>
                        <div className="font-goodtimes text-4xl mt-5 md:text-5xl text-primary-yellow w-2/3">
                            <div>ATG LIGHT PLATE CARRIER</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Lightweight and adaptable with advanced materials and expert craftsmanship
                            </li>
                            <li>
                                Minimalistic and highly durable laser cut design
                            </li>
                            <li>
                                Shoulder and sides ambidextrous quick-release ROC (tubes) system
                            </li>
                            <li>
                                Evacuation handle on the back
                            </li>
                            <li>
                                16 size combinations to perfectly fit the operator
                            </li>
                            <li>
                                The vest comes in 4 sizes, each corresponding to the 4 standards SAPI/ESAPI sizes
                            </li>
                            <li>
                                4 sizes of 3 types of modular cummerbund: Skeletal (lightweight), Pocket (soft armor and hard side armor compatible), and Elastic (better fit)
                            </li>
                            <li>
                                Durable and adaptable with advanced materials and expert craftsmanship
                            </li>
                            <li>
                                NIR (Near-Infrared) materials reduce detection from thermal and infrared cameras
                            </li>
                            <li>
                                Balanced weight with ergonomic fitting
                            </li>
                            <li>
                                Design prevents gear-conflict
                            </li>
                            <li>
                                Lightweight and soft shoulder pads with ventilation
                            </li>
                            <li>
                                Front and back Velcro® panels for ID attachments
                            </li>
                            <li>
                                A full array of gear attachment PALS loops on both the back and the front
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        TECH SPECS
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Materials</div>
                        <div className="w-4/5">
                            Cordura®, Velcro®, 2M®, Murdock Webbing, Texcel. (MIL-SPEC standards, NIR (near-infrared) materials)
                        </div>
                        <div className="mt-5 font-bold">Compatible Plates</div>
                        <div className="w-4/5">
                            SAPI/ESAPI cut Each of 4 vest sizes (S-XL) match proper size of 4 sizes of the plates Up to 1”(30 mm) thickness
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:ml-6">
                        <div className="m-6">
                            <img className="h-auto w-[200%]" src={require('./assets/02-vest02-01.png')} alt="" draggable="false" />
                            <div className="text-center font-semibold">CAMO ARMY</div>
                        </div>
                        <div className="m-6">
                            <img className="h-auto w-[200%]" src={require('./assets/02-vest02-02.png')} alt="" draggable="false" />
                            <div className="text-center font-semibold">DESSERT TAN</div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:ml-6">
                        <div className="m-6">
                            <img className="h-auto w-[200%]" src={require('./assets/02-vest02-03.png')} alt="" draggable="false" />
                            <div className="text-center font-semibold">BLACK</div>
                        </div>
                        <div className="m-6">
                            <img className="h-auto w-[200%]" src={require('./assets/02-vest02-04.png')} alt="" draggable="false" />
                            <div className="text-center font-semibold">OD GREEN</div>
                        </div>
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Vests02;
