import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Armor ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-armor-m md:bg-armor md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <div className="font-goodtimes text-4xl md:text-5xl text-primary-yellow w-2/3 md:w-full">
                            <div>ATG ARMOR SYSTEM</div>
                        </div>
                        <div className="font-goodtimes text-primary-yellow text-xl md:text-3xl">
                            <div>TYPE IIIA</div>
                        </div>
                        <div className="font-inter text-primary-yellow text-sm md:text-base w-4/5">
                            <div>COVER THE MOST VITAL AREAS ON YOUR BODY AND GAIN THE UPPER HAND OVER ANY ENEMY</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-[#93B9B8] mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Increase the area protection by 520% compared to standard plate carriers
                            </li>
                            <li>
                                Consists of 9 elements that can be used separately or in tandem with each other
                            </li>
                            <li>
                                The simple and intuitive installation of all modules allows them to be installed onto any vest, by any manufacturer
                            </li>
                            <li>
                                Optimized for urban combat, patrolling, indoors, as well as public disturbances
                            </li>
                            <li>
                                Expert design of protective elements covers critical parts of the body
                            </li>
                            <li>
                                Total weight comparable to a single Level III steel plate, ranging from 7.7 lbs to 11lbs depending on the protection level
                            </li>
                            <li>
                                Ballistic protection is provided by soft armor panels offering NIJ 0101.06 standard: IIa,II, IIIa. NIJ test report 
                            </li>
                            <li>
                                Modules that allow for hard armor with NIJ III+, NIJ III++ and IV+: SBAM™
                            </li>
                            <li>
                                Ergonomic design provides optimal weight distribution and maintains mobility and comfort during active use, with a negligible effect on mobility
                            </li>
                            <li>
                                The inner power mesh works effectively as passive ventilation
                            </li>
                            <li>
                                Advanced materials ensure effectiveness after long-term use in harsh conditions
                            </li>
                        </ol>
                    </div>
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        TECH SPECS
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="font-bold">Ballistic Performance</div>
                        <ul>
                            <li>.44 Magnum 240 gr SJHP/SWC</li>
                            <li>.357 SIG 125/158 gr FMJ</li>
                            <li>7.62x25 Tokarev SJRN FNH</li>
                            <li>9 mm 50 gr Liberty Civil Defense</li>
                            <li>9 mm 127 gr Ranger SXT +P+</li>
                            <li>.45 ACP 230 gr TMJ</li>
                            <li>FN 5.7x28 40 gr SS197</li>
                            <li>9x19 SXT T-SERIES</li>
                            <li>5.7x28 SS197SR</li>
                            <li>5.7x28 SS195LF</li>
                        </ul>
                    </div>
                    <div className="mx-6 md:ml-12 pt-5">
                        <div className="font-bold">Testing</div>
                        <div className="w-4/5">
                            This product has been independently tested by
                            an NIJ-approved laboratory according to the NIJ
                            Standard 0106.01, and also in certified laboratories
                            according to STANAG 2920 (V50 fragmentation) and
                            DSTU 8835:2019 against Soviet/Russian ammunition
                        </div>
                    </div>
                </div>

                <div className="mx-6 pt-5 md:w-1/3 md:px-24 md:mb-8">
                    <table className="w-full">
                        <tbody>
                            <tr>
                                <td className="text-center font-medium" colSpan="2">CAMO ARMY</td>
                            </tr>
                            <tr>
                                <td>
                                    <img className="h-auto w-[200%]" src={require('./assets/01-armor01.jpg')} alt="" draggable="false" />
                                </td>
                                <td>
                                    <img className="h-auto w-[200%]" src={require('./assets/01-armor02.jpg')} alt="" draggable="false" />
                                </td>
                            </tr>
                            <tr>
                                <td className="mt-5">
                                    <img className="h-auto w-[200%] mt-8" src={require('./assets/01-armor03.jpg')} alt="" draggable="false" />
                                </td>
                                <td>
                                    <img className="h-auto w-[200%] mt-8" src={require('./assets/01-armor04.jpg')} alt="" draggable="false" />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center font-medium">DESERT TAN</td>
                                <td className="text-center font-medium">BLACK</td>
                            </tr>
                            <tr>
                                <td>
                                    <img className="h-auto w-[200%] mt-8" src={require('./assets/01-armor05.jpg')} alt="" draggable="false" />
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="text-center font-medium">OD GREEN</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Armor;
