import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Helmet01 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-helmets01-m md:bg-helmets01 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px] md:h-[178px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">HELMETS</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-5/6">
                            <div>ATG HIGH CUT BALLISTIC HELMET</div>
                        </div>
                        <hr />
                        <div className="text-primary-yellow mt-3">
                            <div>COMFORTABLE AND ADAPTABLE, THIS HELMET IS THE CUTTING EDGE OF BALLISTIC HELMET TECHNOLOGY</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Advanced high-cut ballistic shell made from high-end molded Teijin Twaron® Aramid
                            </li>
                            <li>
                                Optimized performance with ergonomic fitting and double-layered internal padding for comfort
                            </li>
                            <li>
                                Easy reinforcement with FUHA™ Frontal Up Head Armor for significant frontal lobe improved ballistic protection against rifles
                            </li>
                            <li>
                                The ingenious design of the dome extends beyond the coronal suture allowing for a greatly increased margin of protection
                            </li>
                            <li>
                                Ear geometry allows for maximum compatibility with communication devices while reducing the weight of the helmet
                            </li>
                            <li>
                                Aluminum-reinforced polymer shroud compatible with a full range of NVG and NOD devices and extra silicone amortization for stability
                            </li>
                            <li>
                                Super-lightweight polymer side rails for easy installation of comms, tactical cams, side-lighting, sentry visors, or added protection like the SUFA™ Side Up Face Armor
                            </li>
                            <li>
                                The bungee retention system on the shroud reduces interference and snag hazards
                            </li>
                            <li>
                                12-piece dual-layer autonomous foam padding with Velcro® attachments for adjustments to individual comfort and easy maintenance
                            </li>
                            <li>
                            High-capacity Velcro® panels for ID patches and light accessories
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        TECH SPECS
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Ballistic Performance</div>
                        <div className="w-11/12">
                            NIJ STD 0106.01 Level IIIA: 9mm, 44 Mag. & .357 SIG
                        </div>
                        <div className="font-bold">Nato Stanag 2920</div>
                        <div className="w-11/12">
                            17 grain (1.1 gram) FSP V50: 990 m/s (3,250 ft/s)
                        </div>
                        <div className="font-bold">Nato Stanag 2920</div>
                        <div className="w-11/12">
                            Class 1A: 9mm Makarov, Stechkin Use trauma pad to reduce BFD &lt;44mm
                        </div>
                        <div className="mt-5 font-bold">Testing</div>
                        <div className="w-11/12">
                            This product has been independently tested by an NIJ-approved laboratory according to the NIJ Standard 0106.01, and also in certified laboratories according to STANAG 2920 (V50 fragmentation) and DSTU 8835:2019 against Soviet/Russian ammunition
                        </div>
                        <div className="mt-5 font-bold">Areal Density</div>
                        <div className="w-11/12">
                            239.7 lb/ft² (10.1 kg/m²)
                        </div>
                        <div className="font-bold">Capabilities</div>
                        <div className="w-11/12">
                            Multi-hit, minimum 4 shots
                        </div>
                    </div>
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        STYLE
                    </div>
                    <div className="mt-6 mx-24 md:mx-36 mb-8 grow">
                        <img className="h-auto w-[200%]" src={require('./assets/02-helmets01-01.png')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <div className="text-black font-acier text-4xl pt-5">
                        COLORS
                    </div>
                    <div className="flex flex-col md:flex-row md:ml-6">
                        <div className="mx-16 my-6 md:mx-6">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets01-02.png')} alt="" draggable="false" />
                            <div className="text-center font-semibold">BLACK</div>
                        </div>
                        <div className="mx-16 my-6 md:mx-6">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets01-03.png')} alt="" draggable="false" />
                            <div className="text-center font-semibold">COYOTE</div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:ml-6">
                        <div className="mx-16 my-6 md:mx-6">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets01-04.png')} alt="" draggable="false" />
                            <div className="text-center font-semibold">TAN</div>
                        </div>
                        <div className="mx-16 my-6 md:mx-6">
                            <img className="h-auto w-[200%]" src={require('./assets/02-helmets01-05.png')} alt="" draggable="false" />
                            <div className="text-center font-semibold">RANGER GREEN</div>
                        </div>
                    </div>
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Helmet01;
