import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useRef, Fragment } from 'react';
import Main from './Main';
import Home from './Home';
import Fullbody from './Fullbody';
import Duty from './Duty';
import Bullet from './Bullet';
import Riot from './Riot';
import Armor from './Armor';
import Modular from './Modular';
import Vests01 from './Vests01';
import Vests02 from './Vests02';
import BPlates01 from './BPlates01';
import BPlates02 from './BPlates02';
import Apparel01 from './Apparel01';
import Apparel02 from './Apparel02';
import Apparel03 from './Apparel03';
import Apparel04 from './Apparel04';
import Apparel05 from './Apparel05';
import Apparel06 from './Apparel06';
import Apparel07 from './Apparel07';
import Helmet01 from './Helmet01';
import Helmet02 from './Helmet02';
import Helmet03 from './Helmet03';
import Helmet04 from './Helmet04';
import Goggle01 from './Goggle01';
import Goggle02 from './Goggle02';
import Goggle03 from './Goggle03';
import Goggle04 from './Goggle04';
import Bag01 from './Bag01';
import Suit01 from './Suit01';
import Helmet05 from './Helmet05';
import Shield01 from './Shield01';
import Shield02 from './Shield02';
import Shield03 from './Shield03';
import Shield04 from './Shield04';
import Shield05 from './Shield05';
import Baton01 from './Baton01';
import Baton02 from './Baton02';
import Handcuff01 from './Handcuff01';
import Handcuff02 from './Handcuff02';
import Light01 from './Light01';

function App() {
    const [bg, setBg] = useState('');
    const toppage = useRef(null);

    return (
        <Fragment>
        <div ref={toppage}></div>
        <Router>
            <Routes>
                <Route path='/' element={<Main bg={bg} toppage={toppage} />}>
                    <Route index element={<Home setBg={setBg} />} />
                    <Route path='fullbody' element={<Fullbody setBg={setBg} />} />
                    <Route path='bullet' element={<Bullet setBg={setBg} />} />
                    <Route path='riot' element={<Riot setBg={setBg} />} />
                    <Route path='duty' element={<Duty setBg={setBg} />} />
                    <Route path='armor' element={<Armor setBg={setBg} toppage={toppage} />} />
                    <Route path='modular' element={<Modular setBg={setBg} toppage={toppage} />} />
                    <Route path='vests01' element={<Vests01 setBg={setBg} toppage={toppage} />} />
                    <Route path='vests02' element={<Vests02 setBg={setBg} toppage={toppage} />} />
                    <Route path='bplates01' element={<BPlates01 setBg={setBg} toppage={toppage} />} />
                    <Route path='bplates02' element={<BPlates02 setBg={setBg} toppage={toppage} />} />
                    <Route path='apparel01' element={<Apparel01 setBg={setBg} toppage={toppage} />} />
                    <Route path='apparel02' element={<Apparel02 setBg={setBg} toppage={toppage} />} />
                    <Route path='apparel03' element={<Apparel03 setBg={setBg} toppage={toppage} />} />
                    <Route path='apparel04' element={<Apparel04 setBg={setBg} toppage={toppage} />} />
                    <Route path='apparel05' element={<Apparel05 setBg={setBg} toppage={toppage} />} />
                    <Route path='apparel06' element={<Apparel06 setBg={setBg} toppage={toppage} />} />
                    <Route path='apparel07' element={<Apparel07 setBg={setBg} toppage={toppage} />} />
                    <Route path='helmet01' element={<Helmet01 setBg={setBg} toppage={toppage} />} />
                    <Route path='helmet02' element={<Helmet02 setBg={setBg} toppage={toppage} />} />
                    <Route path='helmet03' element={<Helmet03 setBg={setBg} toppage={toppage} />} />
                    <Route path='helmet04' element={<Helmet04 setBg={setBg} toppage={toppage} />} />
                    <Route path='goggle01' element={<Goggle01 setBg={setBg} toppage={toppage} />} />
                    <Route path='goggle02' element={<Goggle02 setBg={setBg} toppage={toppage} />} />
                    <Route path='goggle03' element={<Goggle03 setBg={setBg} toppage={toppage} />} />
                    <Route path='goggle04' element={<Goggle04 setBg={setBg} toppage={toppage} />} />
                    <Route path='bag01' element={<Bag01 setBg={setBg} toppage={toppage} />} />
                    <Route path='suit01' element={<Suit01 setBg={setBg} toppage={toppage} />} />
                    <Route path='helmet05' element={<Helmet05 setBg={setBg} toppage={toppage} />} />
                    <Route path='shield01' element={<Shield01 setBg={setBg} toppage={toppage} />} />
                    <Route path='shield02' element={<Shield02 setBg={setBg} toppage={toppage} />} />
                    <Route path='shield03' element={<Shield03 setBg={setBg} toppage={toppage} />} />
                    <Route path='shield04' element={<Shield04 setBg={setBg} toppage={toppage} />} />
                    <Route path='shield05' element={<Shield05 setBg={setBg} toppage={toppage} />} />
                    <Route path='baton01' element={<Baton01 setBg={setBg} toppage={toppage} />} />
                    <Route path='baton02' element={<Baton02 setBg={setBg} toppage={toppage} />} />
                    <Route path='handcuff01' element={<Handcuff01 setBg={setBg} toppage={toppage} />} />
                    <Route path='handcuff02' element={<Handcuff02 setBg={setBg} toppage={toppage} />} />
                    <Route path='light01' element={<Light01 setBg={setBg} toppage={toppage} />} />
                </Route>
            </Routes>
        </Router>
        </Fragment>
    );
}

export default App;
