import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Apparel07 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-apparel07-m md:bg-apparel07 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">APPAREL</span>
                        <div className="font-goodtimes text-4xl mt-5 md:text-5xl text-primary-yellow w-11/12">
                            <div>ATG ARMORED SPORTS COAT</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="mx-6 md:ml-12">
                        <div className="mt-5 font-bold">
                            <div>A lightweight, removable, armored NIJ level IIIA bulletproof liner.</div>
                        </div>
                        <div className="w-11/12">
                            This modern jacket has a notched lapel and two front buttons. It has a split back for comfortable sitting and button cuffs. Two inside slit pockets and a quality fabric lining.
                        </div>
                    </div>
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Choice of 4 colors and 3 lengths
                            </li>
                            <li>
                                Fitted midsection and chest
                            </li>
                            <li>
                                Modern streamlined design
                            </li>
                            <li>
                                Side Vents
                            </li>
                            <li>
                                Notched lapel
                            </li>
                            <li>
                                Two (2) front buttons
                            </li>
                            <li>
                                Weights approximately 7 lbs
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        COLORS
                    </div>
                    <div className="flex flex-col md:flex-row md:mx-6">
                        <div className="mx-24 md:mx-5 mt-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel07-01.jpg')} alt="" draggable="false" />
                            <div className="text-center font-semibold">BLACK</div>
                        </div>
                        <div className="mx-24 md:mx-5 mt-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel07-02.jpg')} alt="" draggable="false" />
                            <div className="text-center font-semibold">CAMEL</div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:mx-6">
                        <div className="mx-24 md:mx-5 mt-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel07-03.jpg')} alt="" draggable="false" />
                            <div className="text-center font-semibold">CHARCOAL</div>
                        </div>
                        <div className="mx-24 md:mx-5 mt-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel07-04.jpg')} alt="" draggable="false" />
                            <div className="text-center font-semibold">VICUNA</div>
                        </div>
                    </div>
                 </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Apparel07;
