import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Suit01 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-suit01-m md:bg-suit01 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[293px] md:h-[178px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">SUITS</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-dark-yellow w-5/6">
                            <div>ATG SPARTAN RIOT SUITS</div>
                        </div>
                        <hr />
                        <div className="text-primary-yellow mt-3 md:w-9/12">
                            <div>MODULAR RIOT SUIT WITH INTEGRATED CARRIERS AND FULL-COVERAGE MOLLE</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12 md:flex md:flex-row">
                        <ol className="list-decimal pl-8 pr-6 pt-2 md:pb-2">
                            <li>
                                Modular Design
                            </li>
                            <li>
                                Full-coverage MOLLE
                            </li>
                            <li>
                                Multi-function Ballistic Carrier
                            </li>
                            <li>
                                Fire Resistant
                            </li>
                            <li>
                                Ultra Lightweight
                            </li>
                            <li>
                                Blunt Force Resistant
                            </li>
                        </ol>
                        <ol className="list-decimal pl-8 pr-6 pb-2 md:pt-2" start="7">
                            <li>
                                Stab Resistant
                            </li>
                            <li>
                                Integrated Hydration
                            </li>
                            <li>
                                Integrated Cooling
                            </li>
                            <li>
                                4-way ID System
                            </li>
                            <li>
                                Body Camera Ready
                            </li>
                        </ol>
                    </div>
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            The Spartan ATG Riot Suit takes the mobility and unparalleled protection of the Enforcer a step further. Full-coverage MOLLE to add any additional accessories you may need for intense tactical situations. The integrated carriers are fitted for hard and/or soft ballistic for protection in critical, life threatening scenarios.
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            Removable and replaceable hydration and cooling systems keep operators comfortable in extreme conditions.
                        </div>    
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="flex flex-col md:flex-row md:ml-6">
                        <div>
                            <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                                TECH SPECS
                            </div>
                            <div className="mx-6 md:ml-12">
                                <div className="mt-3 font-bold">Material</div>
                                <div className="w-11/12">
                                    Molded Polypropylene Nylon.                        </div>
                                <div className="font-bold">Weight</div>
                                <div className="w-11/12">
                                    11 lbs                        </div>
                                <div className="font-bold">Included</div>
                                <div className="w-11/12">
                                    <ul className="list-disc pl-5 pr-6 py-2">
                                        <li>
                                            Chest & Back Panels
                                        </li>
                                        <li>
                                            Upper and lower arm protections
                                        </li>
                                        <li>
                                            Thigh, shin and foot protection
                                        </li>
                                        <li>
                                            Removable groin protection
                                        </li>
                                        <li>
                                            Full-coverage MOLLE
                                        </li>
                                        <li>
                                            Integrated ballistic carriers
                                        </li>
                                        <li>
                                            Optional Puncture Panels
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mx-32 my-6 md:mx-0">
                            <img className="h-auto w-[200%]" src={require('./assets/03-suit01-01.png')} alt="" draggable="false" />
                        </div>
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Suit01;
