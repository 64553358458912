import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Handcuff02 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-handcuff02-m md:bg-handcuff02 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[260px] md:h-[300px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">HANDCUFFS</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-11/12 md:w-5/6">
                            <div>ATG FORGED CUFFS</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Length: 9.125"   |   Width: 3.125"   |   Weight: 9 oz.
                            </li>
                            <li>
                                Edge Radius: 0.035” minimum
                            </li>
                            <li>
                                Forged aluminum construction
                            </li>
                            <li>
                                Hardcoat anodized
                            </li>
                            <li>
                                Replaceable and interchangeable lock sets with double sided keyways
                            </li>
                            <li>
                                Can fit large or small wrists while remaining in control
                            </li>
                            <li>
                                Provides more restricted control of large subjects
                            </li>
                            <li>
                                One pawl tactical design offers a basic level of security for standard use and feature a replaceable lock set
                            </li>
                            <li>
                                Two pawl security design provides increased security with a stainless steel divider plate, making these cuffs virtually impossible to pick. This design requires an included custom double bit key
                            </li>
                            <li>
                                Three pawl European design uses three independently operating sections to make them more resistant to shimming
                            </li>
                            <li>
                                Includes one pentagon key
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            ATG Forged Cuffs are the first forged aluminum rigid frame restraint, incorporating the innovative advances in handcuff design that were pioneered by ATG. They are lightweight and feature round smooth edges, interchangeable lock sets, conical bows and double sided keyways.
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            Added to these revolutionary design elements is a frame that is forged from 7075 T6 ordnance grade aluminum and permanently joined by 4140 heat treated spiral locking pins. This ATG design incorporates the practical features most requested by officers on the street, meaning that these handcuffs are manufactured to fit your needs.
                        </div>    
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            The frame geometry, deep set teeth and precision smooth action eliminates the need for back loading. These cuffs come in one pawl, two pawl and three pawl designs. The Forged Cuffs employ innovative technologies that are available only from ATG.
                        </div>    
                    </div>
                    <div className="mt-6 mb-8 mx-8 md:mx-36">
                        <img className="h-auto w-[200%]" src={require('./assets/04-handcuff02-01.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Handcuff02;
