import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Goggle04 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-goggle04-m md:bg-goggle04 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[325px] md:h-[300px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">GOGGLES</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-11/12 md:w-5/6">
                            <div>WILEY X NERVE (APEL)</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Coyote tan authorized for wear with Operational Camouflage Pattern (OCP) uniform
                            </li>
                            <li>
                                Low-profile, nearly pocket-sized design
                            </li>
                            <li>
                                Shatterproof Selenite polycarbonate lenses meet MIL-PRF-32432 (GL) ballistic standards
                            </li>
                            <li>
                                Authorized Protective Eyewear List (APEL) approved
                            </li>
                            <li>
                                Meets ballistic MIL-V-43511C standards
                            </li>
                            <li>
                                T-Shell lens coating prevents scratching
                            </li>
                            <li>
                                100% UVA and UVB protection
                            </li>
                            <li>
                                Foil anti-fog coating
                            </li>
                            <li>
                                Symmetrical venting system allows air to escape, reducing fog
                            </li>
                            <li>
                                Night Vision Goggle (NVG) compatible
                            </li>
                            <li>
                                Spheric lens design creates an unparalleled field of vision
                            </li>
                            <li>
                                Hard case included with GSA or NSN approved items
                            </li>
                            <li>
                                First tactical goggles designed to fit both men and women
                            </li>
                            <li>
                                Prescription lens compatible
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            The first tactical goggles designed to fit both men and women, the Wiley X Nerve goggles received approval for the U.S. Army's Authorized Protective Eyewear List (APEL), so you know they're tough. They feature an innovative Spheric lens design for a superior field of vision and a symmetrical venting system to  prevent fogging.
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            For ultimate protection in the field, these goggles use shatterproof Selenite polycarbonate lenses, which meet ANSI standards for high velocity and high impact protection.
                        </div>    
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            A T-Shell lens coating eliminates scratching, while the Foil coating keeps the lenses from fogging up. Nearly small enough to fit in your pocket, these sleek goggles are built to handle the most demanding challenges.
                        </div>    
                    </div>
                    <div className="mt-6 mx-8 md:mx-20">
                        <img className="h-auto w-[200%]" src={require('./assets/02-goggle04-01.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Goggle04;
