import { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";

function Duty ({ setBg }) {
    useEffect(() => {
        setBg('bg-duty-m md:bg-duty md:bg-right-top');
    }, [setBg]);

    return (
        <div className="w-full flex flex-col">
            <div className="h-[260px] md:h-[100px]" />
            <div className="ml-6 md:ml-12 mb-10 font-goodtimes text-primary-yellow md:text-primary-dark-yellow text-left text-6xl md:text-8xl md:w-1/2">
                <div>DUTY GEARS</div>
            </div>
            <div className="mx-6 md:ml-56 mb-8 md:w-1/5">
                <svg viewBox="0 0 510.7 554.8">
                <g id="g52" transform="scale(1.0113)">
                    <polygon id="polygon6" stroke="#FFFFFF" strokeWidth="4.1666" strokeMiterlimit="10.113" points="111.4,182.2 57.5,275.5 
                        111.4,368.8 219.1,368.8 272.9,275.5 219.1,182.2 	"/>
                    <path id="path8" fill="none" stroke="#FFFFFF" strokeWidth="2.5485" strokeMiterlimit="10.113" d="M212.5,314.1
                        c-3.2,3.2-6.2,6.2-9.3,9.3c-0.2-0.2-0.4-0.3-0.6-0.5c-31.5-31.5-63-63-94.6-94.6c-2.2-2.1-2.7-4.9-1.5-7.5c1.2-2.6,3.7-4,6.6-3.8
                        c1.6,0.1,3,0.9,4.1,2c7.4,7.4,14.8,14.8,22.3,22.3l72.1,72.1C211.8,313.7,212.2,313.9,212.5,314.1L212.5,314.1z"/>
                    <path id="path10" fill="none" stroke="#FFFFFF" strokeWidth="2.5485" strokeMiterlimit="10.113" d="M195.5,291l-8.9-8.9
                        c6.1-6.1,12.2-12.2,18.3-18.3c2.9,3,6,6,8.9,9C207.7,278.8,201.6,284.9,195.5,291L195.5,291z"/>
                    <path id="path12" fill="none" stroke="#FFFFFF" strokeWidth="2.5485" strokeMiterlimit="10.113" d="M209,333.9
                        c-1.4,0-2.8-0.9-3.6-2.5c-0.8-1.6-0.6-3.3,0.7-4.6c3.2-3.3,6.4-6.5,9.6-9.6c1.7-1.6,4.4-1.4,6,0.2s1.6,4.3,0,6
                        c-1.3,1.4-2.7,2.7-4.1,4.1l-5.2,5.2C211.7,333.5,210.6,333.9,209,333.9L209,333.9z"/>
                    <path id="path14" fill="none" stroke="#FFFFFF" strokeWidth="2.5485" strokeMiterlimit="10.113" d="M210.8,253
                        c1.3,0,2.3,0.4,3.1,1.2c3.1,3.1,6.2,6.2,9.3,9.3c1.7,1.7,1.7,4.4,0,6.1s-4.4,1.7-6.1,0c-3.1-3.1-6.2-6.2-9.3-9.3
                        c-1.3-1.3-1.6-3.1-0.9-4.8C207.7,253.9,209.1,253.1,210.8,253L210.8,253z"/>
                    <polygon id="polygon16" stroke="#FFFFFF" strokeWidth="4.1666" strokeMiterlimit="10.113" points="296.8,79.1 242.9,172.4 
                        296.8,265.6 404.5,265.6 458.4,172.4 404.5,79.1 	"/>
                    <path id="path18" fill="#FFFFFF" d="M311,164.9c-0.1-0.2-0.1-0.4-0.2-0.5c-0.6-2.1-1.2-4.2-1.8-6.2c-0.1-0.4,0-0.6,0.2-0.9
                        c3.7-5.2,7.4-10.5,11-15.8c0.1-0.1,0.2-0.2,0.3-0.4c3.7,2.2,7.4,4.3,11.2,6.5c0.4-0.8,0.8-1.6,1.1-2.4c-0.1-0.1-0.2-0.2-0.3-0.2
                        c-3.7-2.3-4.5-7.2-1.7-10.6c1-1.2,2-2.3,3-3.5c0.6-0.7,1.1-1.3,1.7-2c1-1.2,2.3-2,3.8-2.3c0.3-0.1,0.4-0.2,0.5-0.4
                        c0.9-2.8,2.8-4.5,5.6-5.1c0.9-0.2,1.8-0.2,2.7-0.3c1.8-0.2,3.5-0.3,5.3-0.4c2.7-0.1,4.8,1,6.4,3.2c0.1,0.2,0.2,0.3,0.3,0.5
                        c0.7,0.1,1.4,0,2,0.1c2.2,0.4,4,1.6,5,3.5c1.2,2.2,2.4,4.5,3.5,6.9c1.5,3.2,0.4,7.1-2.6,9c-0.2,0.1-0.3,0.2-0.6,0.4
                        c0.2,1.3,0.4,2.6,0.6,3.8c0,0,0.1,0,0.1,0h0c0,0,0.1-0.1,0.1-0.1c3.3-2.3,6.5-4.6,9.8-6.8h0.1c0.1,0.1,0.3,0.3,0.4,0.5
                        c3.9,4.8,7.8,9.6,11.7,14.4c0.2,0.3,0.4,0.4,0.8,0.4c5.8,0.4,11.4,1.6,16.6,4.1c3.9,1.9,7.5,4.3,10.4,7.6c3.4,3.8,5.6,8.1,6.9,13
                        c0.9,3.5,1.2,7,1,10.6c-0.3,5.4-1.9,10.4-4.5,15.1c-1.9,3.3-4.2,6.3-6.9,8.9c-1.2,1.2-2.6,1.8-4.3,1.7c-3.1-0.1-5.7-2.9-5.5-6.1
                        c0.1-1.4,0.6-2.6,1.6-3.7c2-2.1,3.8-4.2,5.5-6.6c1-1.5,1.9-3,2.6-4.6c1.1-2.4,1.5-4.9,1.2-7.6c-0.4-3.3-1.3-6.4-2.9-9.3
                        c-1.9-3.3-4.4-5.9-7.5-8c-4.1-2.7-8.6-4.2-13.4-4.8c-4.9-0.6-9.5,0.5-13.7,3c-3.1,1.8-5.7,4.2-7.9,7c-0.3,0.4-0.6,0.9-1,1.4
                        c-0.7-0.1-1.4-0.2-2.1-0.3c-1.6-0.2-3.2-0.5-4.8-0.7c-0.4-0.1-0.6-0.2-0.8-0.5c-3.2-5.6-6.4-11.1-9.6-16.6
                        c-0.1-0.1-0.2-0.3-0.3-0.5c2.3-1.6,4.6-3.2,6.9-4.8c2.3-1.6,4.6-3.2,6.9-4.8c-0.2-1.6-0.5-3.3-0.7-5c-0.5,0-1-0.1-1.4-0.1
                        c-2.5-0.3-4.3-1.6-5.4-3.8c-1-1.9-2-3.8-2.9-5.6c-0.1-0.2-0.2-0.4-0.3-0.6c-2.2,0.2-4.4,0.4-6.7,0.5c0,0.1-0.1,0.2-0.1,0.3
                        c-0.3,1.1-0.9,2-1.6,2.8c-1.5,1.7-3,3.4-4.4,5.1c-1.6,1.8-3.6,2.7-6,2.6c0,0-0.1,0-0.2,0c-0.5,0.9-0.9,1.9-1.4,2.9
                        c1.2,0.7,2.4,1.4,3.6,2.1c1.2,0.7,2.4,1.4,3.5,2.1c1.2,0.7,2.4,1.4,3.6,2.1c1.2,0.7,2.4,1.4,3.6,2.1c-0.1,0.2-0.2,0.4-0.3,0.6
                        c-2.7,5.6-5.4,11.1-8.1,16.7c-0.2,0.4-0.2,0.8,0,1.2c1.4,3.6,2.4,7.2,2.9,11c0.3,2.1,0.4,4.3,0.3,6.5c-0.2,6-1.8,11.7-5.2,16.7
                        c-2.5,3.8-5.9,6.8-9.7,9.1c-4,2.4-8.2,4.1-12.9,4.8c-2.9,0.5-5.8,0.5-8.7,0.3c-3.8-0.3-7.4-1.2-11-2.5c-1.8-0.7-3-2-3.5-3.9
                        c-0.9-3.4,1.6-6.7,4.9-7c0.9-0.1,1.7,0,2.6,0.3c2.5,0.9,5.1,1.6,7.8,2.1c2.2,0.4,4.4,0.6,6.7,0.5c1.8-0.1,3.5-0.5,5.1-1.3
                        c6.6-3.4,10.7-8.7,12.4-15.9c0.8-3.2,0.8-6.5,0.4-9.8c-0.3-2.4-0.8-4.7-1.7-6.9c-2.1-5.3-5.8-9.2-10.8-11.8
                        c-2.4-1.3-4.9-2.2-7.6-2.7C311.9,165,311.5,164.9,311,164.9L311,164.9z M321.4,144.8c-0.1,0.1-0.2,0.2-0.2,0.3
                        c-3,4.2-5.9,8.4-8.9,12.7c-0.2,0.2-0.2,0.5-0.1,0.8c0.3,1.1,0.7,2.3,1,3.4c0.1,0.3,0.3,0.4,0.5,0.5c3,0.7,5.9,1.9,8.6,3.5
                        c4.7,2.8,8.3,6.6,10.6,11.7c1.6,3.6,2.3,7.4,2.5,11.4c0.1,2.6,0,5.2-0.6,7.8c-1.9,8.2-6.6,14.2-14.1,18c-1.3,0.7-2.7,1.1-4.1,1.3
                        c-2.7,0.5-5.3,0.3-8-0.1c-3-0.4-6-1.2-8.9-2.2c-0.4-0.1-0.8-0.3-1.1-0.4c-2-0.5-3.8,1.4-3.4,3.4c0.2,1.1,0.9,1.7,1.9,2.1
                        c3.2,1.2,6.5,2,9.9,2.3c2.8,0.2,5.6,0.2,8.3-0.3c4.7-0.8,9-2.6,13-5.3c5.1-3.5,8.7-8.2,10.6-14.1c1.6-4.9,1.8-9.9,1.1-15
                        c-0.5-3.8-1.6-7.6-3.1-11.1c-0.2-0.4-0.1-0.7,0-1c2.5-5.2,5-10.3,7.6-15.5c0.1-0.2,0.2-0.4,0.2-0.6
                        C337,153.9,329.2,149.4,321.4,144.8L321.4,144.8z M377.8,144.6c-7.5,5.2-14.8,10.3-22.2,15.5c0.1,0.2,0.2,0.4,0.3,0.5
                        c1,1.6,1.9,3.3,2.9,5c1.6,2.7,3.2,5.4,4.8,8.2c0.2,0.3,0.4,0.5,0.7,0.5c0.9,0.1,1.8,0.3,2.6,0.4c0.4,0.1,0.8,0.1,1.2,0.2
                        c0.1-0.1,0.2-0.2,0.3-0.3c2.2-2.9,4.9-5.3,8-7.2c3.6-2.2,7.4-3.5,11.6-3.8c2.4-0.2,4.8,0,7.1,0.5c3.5,0.8,6.9,1.9,10.1,3.7
                        c5.4,3.1,9.4,7.4,11.7,13.1c1.1,2.6,1.7,5.3,1.9,8.1c0.1,1.3,0.1,2.7-0.1,4c-0.4,2.1-1.2,4.1-2.2,6c-1.8,3.3-4.1,6.3-6.6,9.1
                        c-0.4,0.5-0.9,0.9-1.3,1.4c-0.9,1.1-1,2.6-0.1,3.6c1,1.3,3,1.5,4.3,0.2c3.2-3.1,5.8-6.7,7.6-10.8c1.6-3.5,2.6-7.1,2.9-10.9
                        c0.2-2.7,0.1-5.5-0.4-8.2c-0.6-3.5-1.8-6.8-3.7-9.8c-2.6-4.2-6.1-7.4-10.5-9.8c-5.8-3.2-12.1-4.7-18.7-4.9c-0.4,0-0.7-0.1-0.9-0.5
                        c-3-3.6-5.9-7.3-8.9-10.9C379.4,146.6,378.6,145.6,377.8,144.6L377.8,144.6z M357.4,133.7c1.1,2.1,2.1,4.1,3.2,6.1
                        c0.6,1.1,1.6,1.7,2.9,2c0.3,0.1,0.6,0.1,0.9,0c-0.1-0.6-0.2-1.2-0.2-1.8c1-0.1,1.9-0.3,2.8-0.4c0.1,0.4,0.1,0.7,0.2,1.1
                        c0.1-0.1,0.3-0.2,0.3-0.3c1.2-1.4,1.4-3,0.6-4.6c-1.1-2.2-2.2-4.4-3.4-6.6c-0.5-1-1.4-1.7-2.5-2c-0.4-0.1-0.7-0.2-1.1-0.1
                        C361.1,129.8,359.9,132.1,357.4,133.7L357.4,133.7z M337,143c0.3-0.1,0.4-0.1,0.6-0.1c0.8-0.2,1.4-0.6,1.9-1.2
                        c0.4-0.4,0.8-0.9,1.2-1.4c1.1-1.3,2.2-2.6,3.4-3.9c0.3-0.4,0.7-0.8,0.8-1.4c-2.8-0.9-4.5-2.7-5.3-5.5c-0.8,0.2-1.4,0.6-1.9,1.2
                        c-0.6,0.6-1.1,1.3-1.7,2c-1,1.1-2,2.3-3,3.4c-1.5,1.8-1.4,4.4,0.3,6c0.2,0.2,0.4,0.3,0.7,0.5c0.4-0.8,0.8-1.6,1.2-2.4
                        c0.9,0.4,1.7,0.8,2.6,1.2C337.5,141.9,337.3,142.4,337,143L337,143z M357.2,125c-1-1.2-2.1-1.9-3.6-1.8c-2.5,0.2-4.9,0.4-7.4,0.6
                        c-1.3,0.1-2.4,0.8-3.1,1.8c-0.2,0.3-0.3,0.6-0.5,0.9c1.4,0.4,2.6,1,3.5,2.1c1,1,1.5,2.2,1.8,3.6c1.9-0.2,3.8-0.3,5.7-0.5
                        C353.6,128.8,354.8,126.6,357.2,125L357.2,125z M342.8,129.7c0.4,1,1,1.7,2,2.1C344.5,130.8,343.8,130.1,342.8,129.7z M357.9,128.1
                        c-0.7,0.6-1.1,1.4-1.2,2.3C357.4,129.8,357.8,129,357.9,128.1z"/>
                    <path id="path20" fill="#FFFFFF" d="M306.6,156.2c1,3.3,2,6.5,3,9.8c-0.5,0.1-0.9,0.1-1.3,0.1c-2,0.2-4,0.7-6,1.3
                        c-3.3,1.1-6.3,2.6-8.9,4.8c-3.6,3-5.9,6.9-6.8,11.5c-0.5,2.2-0.6,4.4-0.4,6.6c0.2,3.1,1.1,6,2.4,8.8c1.1,2.3,2.4,4.5,4,6.5
                        c0.4,0.5,0.9,1,1.4,1.6c-2.3,2.8-4.6,5.6-6.9,8.4c-0.7-0.4-1.4-0.8-2-1.3c-2-1.7-3.6-3.7-4.9-5.9c-2.1-3.4-3.4-7.1-4.2-11
                        c-0.6-3.1-1-6.3-0.9-9.5c0.1-4,1.1-7.8,2.9-11.5c2.4-4.9,5.8-8.9,10-12.3c4.7-3.7,10-6.2,15.9-7.5
                        C304.8,156.5,305.7,156.4,306.6,156.2L306.6,156.2z M290.3,207.5c-0.1-0.2-0.2-0.3-0.3-0.4c-1.8-2.3-3.2-4.8-4.4-7.4
                        c-1.3-3.1-2.1-6.4-2.3-9.8c-0.1-2.2,0.1-4.4,0.5-6.5c1.2-5.8,4-10.5,8.8-14.1c3.3-2.5,7-4.1,11-5.1c0.7-0.2,1.5-0.4,2.3-0.5
                        c-0.4-1.4-0.9-2.7-1.3-4.1c-0.3,0.1-0.5,0.1-0.7,0.1c-5.7,1.4-10.9,3.9-15.4,7.8c-3.8,3.2-6.8,7.1-8.7,11.7c-1.4,3.3-2,6.8-2,10.4
                        c0,1.5,0.2,3.1,0.4,4.6c0.4,3.3,1.3,6.5,2.6,9.6c1.3,2.8,2.9,5.4,5.1,7.6c0.2,0.2,0.3,0.3,0.5,0.5
                        C287.9,210.3,289.1,208.9,290.3,207.5L290.3,207.5z"/>
                    <path id="path22" fill="#FFFFFF" d="M400.8,211.2c1.5,3.3,3.1,6.5,4.7,9.8c-0.6,0.3-1.1,0.7-1.7,1c-1.8,0.9-3.7,1.5-5.7,1.9
                        c-3,0.6-6,0.7-9,0.4c-4.8-0.5-9.4-1.8-13.7-3.9c-5.4-2.6-9.4-6.7-12.4-11.9c-2.2-3.7-3.6-7.8-4.3-12c-0.5-2.6-0.7-5.2-0.6-7.9
                        c0.1-3.4,0.6-6.8,1.6-10.1c0-0.1,0-0.1,0.1-0.2c3.3,0.4,6.7,0.9,10,1.4c0,0.1,0,0.3-0.1,0.4c-1.1,2.9-1.6,5.9-1.8,9
                        c-0.2,3.8,0.3,7.5,1.8,11.1c1.6,3.7,4.2,6.5,7.5,8.7c3,2,6.4,3.2,10,3.7c4.4,0.6,8.7,0.3,13-1.1
                        C400.4,211.2,400.6,211.2,400.8,211.2L400.8,211.2z M361.8,181.4c-0.4,1.8-0.7,3.6-0.8,5.4c-0.2,2.7-0.1,5.3,0.3,8
                        c0.5,3.7,1.6,7.3,3.3,10.7c2.2,4.4,5.4,8.1,9.5,10.8c2.2,1.4,4.5,2.4,7,3.3c2.9,1,5.9,1.6,9,1.9c1.9,0.2,3.8,0.1,5.7-0.1
                        c1.9-0.2,3.7-0.6,5.5-1.4c0.1-0.1,0.2-0.1,0.4-0.2c-0.8-1.7-1.6-3.4-2.5-5.1c-0.2,0.1-0.4,0.1-0.6,0.1c-2.3,0.5-4.6,0.8-6.9,0.8
                        c-1.5,0-3.1-0.1-4.6-0.3c-3.9-0.5-7.5-1.8-10.9-3.9c-3.9-2.5-7-5.7-9-9.9c-1.6-3.4-2.2-7-2.3-10.7c0-2.4,0.2-4.7,0.7-7.1
                        c0.1-0.6,0.2-1.1,0.4-1.7C364.6,181.8,363.3,181.6,361.8,181.4L361.8,181.4z"/>
                    <path id="path24" fill="#FFFFFF" d="M328.2,152.2c2,1.1,4,2.1,6,3.2c-1.6,3.5-3.2,7-4.8,10.6c-2.2-1.4-4.4-2.8-6.6-4.2
                        C324.6,158.5,326.4,155.4,328.2,152.2L328.2,152.2z M328.1,161.7c0.8-1.7,1.5-3.4,2.3-5.1c-0.4-0.2-0.7-0.4-1.1-0.6
                        c-0.9,1.6-1.8,3.2-2.7,4.8C327.2,161.1,327.6,161.4,328.1,161.7L328.1,161.7z"/>
                    <path id="path26" fill="#FFFFFF" d="M365,156.9c1.8-1.4,3.6-2.7,5.4-4.1c2.5,2.9,5,5.9,7.6,8.8c-2.2,1.4-4.4,2.8-6.6,4.2
                        C369.3,162.9,367.2,159.9,365,156.9z M369.1,157.5c0.4,0.7,2.9,4.2,3.2,4.4c0.5-0.3,0.9-0.6,1.4-0.9c-1.2-1.4-2.4-2.8-3.7-4.2
                        C369.7,157,369.4,157.3,369.1,157.5z"/>
                    <polygon id="polygon28" stroke="#FFFFFF" strokeWidth="4.1666" strokeMiterlimit="10.113" points="299.5,283.2 245.6,376.5 
                        299.5,469.8 407.2,469.8 461,376.5 407.2,283.2 	"/>
                    <path id="path30" fill="#FFFFFF" d="M295.6,414.1c-0.5-1.4,0.1-2.4,1.1-3.4c15.2-14.8,30.4-29.7,45.6-44.5c0.5-0.5,1-1.3,1.1-2.1
                        c2.2-9.8,4.4-19.6,6.6-29.5c0.5-2.3,1.5-4.2,3.3-5.8c3-2.7,5.8-5.6,8.6-8.4c1.7-1.6,2.8-1.6,4.4,0.1c14.5,14.9,29.1,29.9,43.6,44.8
                        c1.7,1.7,1.7,2.8,0,4.4c-2.9,2.8-5.8,5.5-8.6,8.4c-1.7,1.7-3.6,2.7-5.9,3.1c-9.9,1.9-19.9,3.9-29.8,5.8c-0.7,0.1-1.4,0.5-1.9,1
                        c-15.3,14.8-30.6,29.6-45.8,44.5c-1,1-2,1.5-3.4,1C308.2,427.1,301.9,420.6,295.6,414.1L295.6,414.1z M354.2,337
                        c-0.1,0.6-0.2,1-0.3,1.4c-2.1,9.2-4.1,18.4-6.2,27.6c-0.2,1.1-0.7,2-1.6,2.8c-11.3,11-22.6,22-33.9,33.1c-0.3,0.3-0.7,0.7-1.1,1
                        c5,5.2,10,10.2,15,15.4c0.4-0.4,0.7-0.7,1.1-1c11.4-11,22.7-22,34-33c0.8-0.7,1.6-1.2,2.6-1.4c9.3-1.8,18.6-3.6,28-5.5
                        c0.4-0.1,0.8-0.2,1.2-0.3C380.2,363.7,367.3,350.4,354.2,337L354.2,337z M356.2,332.4c13.9,14.3,27.8,28.5,41.6,42.8
                        c2.7-2.6,5.3-5.1,7.9-7.7c-13.9-14.3-27.8-28.5-41.6-42.8C361.4,327.3,358.8,329.8,356.2,332.4z M322.8,421.4
                        c-5-5.1-9.9-10.2-14.9-15.3c-2.4,2.3-4.7,4.6-7,6.9c5,5.1,9.9,10.2,14.9,15.3C318.2,426,320.5,423.7,322.8,421.4z"/>
                    <path id="path32" fill="#FFFFFF" d="M351.6,388.7c-2.2,2.1-4.4,4.3-6.6,6.4c-1.4,1.3-2.5,1.3-3.8,0c-2.1-2.2-4.3-4.4-6.4-6.6
                        c-1.3-1.3-1.3-2.5,0.1-3.8c4.4-4.3,8.9-8.6,13.3-13c1.3-1.3,2.5-1.3,3.8,0c2.1,2.2,4.3,4.4,6.4,6.6c1.3,1.3,1.3,2.5-0.1,3.8
                        C356.1,384.3,353.9,386.5,351.6,388.7L351.6,388.7z M350.2,383.7c1.3-1.2,2.5-2.4,3.6-3.5l-3.8-3.9c-1.2,1.2-2.4,2.4-3.6,3.5
                        C347.6,381.1,348.9,382.4,350.2,383.7z M343.2,390.5c1.3-1.2,2.5-2.4,3.6-3.5c-1.3-1.3-2.5-2.6-3.8-3.9c-1.2,1.2-2.4,2.4-3.6,3.5
                        C340.7,387.9,341.9,389.2,343.2,390.5z"/>
                    <rect id="rect34" y="380.4" fill="#F5F5D8" width="219.9" height="66.1"/>
                    
                        <text transform="matrix(0.9888 0 0 0.9888 20.8498 426.7794)" fill="#4D4927" fontFamily="'Good Times'" fontSize="38.8137px">BATON</text>
                    <rect id="rect40" x="173.5" y="0" fill="#F5F5D8" width="331.5" height="66.1"/>
                    
                        <text transform="matrix(0.9888 0 0 0.9888 194.37 46.3594)" fill="#4D4927" fontFamily="'Good Times'" fontSize="38.8137px">HANDCUFFS</text>
                    <rect id="rect46" x="297.6" y="482.5" fill="#F5F5D8" width="185.9" height="66.1"/>
                    
                        <text transform="matrix(0.9888 0 0 0.9888 313.1996 528.8598)" fill="#4D4927" fontFamily="'Good Times'" fontSize="38.8137px" letterSpacing="-2">LIGHTS</text>
                </g>
                </svg>
            </div>

            <div className="flex flex-col mb-12 md:mx-9">
                <div className="flex flex-col md:flex-row">
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/baton01">
                            <img className="h-auto w-[200%]" src={require('./assets/04-01-duty.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-baton bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/baton02">
                            <img className="h-auto w-[200%]" src={require('./assets/04-02-duty.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-baton bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/handcuff01">
                            <img className="h-auto w-[200%]" src={require('./assets/04-03-duty.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-handcuff bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                </div>

                <div className="flex flex-col md:flex-row">
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/handcuff02">
                            <img className="h-auto w-[200%]" src={require('./assets/04-04-duty.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-handcuff bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <button className="group mx-6 my-3 md:mx-3 relative grow">
                        <Link to="/light01">
                            <img className="h-auto w-[200%]" src={require('./assets/04-05-duty.jpg')} alt="" />
                            <div className="absolute bottom-3 left-3 w-[100px] md:w-[130px] h-full bg-light bg-left-bottom bg-no-repeat group-hover:w-[180px]" />
                        </Link>
                    </button>
                    <div className="mx-6 my-3 md:mx-3 relative grow md:block hidden">
                        <img className="h-auto w-[200%]" src={require('./assets/02-00-bullet.jpg')} alt="" />
                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center"><Logo width="130"/></div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Duty;
