import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Apparel02 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-apparel02-m md:bg-apparel02 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px] md:h-[250px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">APPAREL</span>
                        <div className="font-goodtimes text-4xl mt-5 md:text-5xl text-primary-yellow w-11/12">
                            <div>ATG ARMORED HOODIE DEFENDER 1</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="mx-6 md:ml-12">
                        <div className="mt-5 font-bold">Style meets protection - hide in plain view</div>
                        <div className="w-11/12">
                            Designed prefectly to blend into everyday life. Appearing wholly unremarkable to the outside world. Offering protection from common threats including handguns and fragmentation, It is suited for the urban jungle.
                        </div>
                    </div>

                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Appearance identical to a modern monochrome Hoodie
                            </li>
                            <li>
                                Balanced weight with ergonomic fitting
                            </li>
                            <li>
                                Durable and adaptable with advanced materials and expert craftsmanship
                            </li>
                            <li>
                                Compatible with all belts and holsters
                            </li>
                            <li>
                                Anti-bacterial Coolmax® inner layer keeps the wearer fresh and dry
                            </li>
                            <li>
                                Compatible with all plate carriers
                            </li>
                            <li>
                                Soft armor protection provided by UHMWPE panels of NIJ 0101.06: IIa, II, and IIIa
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-5">
                        <div className="mx-20 md:mx-48">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel02-01.png')} alt="" draggable="false" />
                        </div>
                        <div className="mx-20 md:mx-48 mx-">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel02-02.png')} alt="" draggable="false" />
                        </div>
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Apparel02;
