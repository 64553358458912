import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Shield04 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-shield04-m md:bg-shield04 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[380px] md:h-[240px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">SHIELD</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-dark-yellow md:text-primary-yellow w-5/6">
                            <div>CAPTURE SHIELD</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-11/12">
                            The capture version of the NBS features a concave shape, making it ideal for use in prisons, riots and other crowd control situations. Personnel remain protected, using the NBS's unique shape to subdue defiant persons by pinning them against walls or the ground. The 42” Capture Shield is particularly effective aiding guards in extracting disorderly inmates from cells.
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:ml-6 md:justify-between">
                        <div className="mx-32 my-6 md:mx-8 md:mb-1">
                            <img className="h-auto w-[200%]" src={require('./assets/03-shield04-01.jpg')} alt="" draggable="false" />
                            <div className="text-center font-semibold">Capture Shield (Front)</div>
                        </div>
                        <div className="mx-32 my-6 md:mx-8 md:mb-1">
                            <img className="h-auto w-[200%]" src={require('./assets/03-shield04-02.jpg')} alt="" draggable="false" />
                            <div className="text-center font-semibold">Capture Shield (Rear)</div>
                        </div>
                        <div className="mx-32 my-6 md:mx-8 md:mb-1">
                            <img className="h-auto w-[200%]" src={require('./assets/03-shield04-03.jpg')} alt="" draggable="false" />
                            <div className="text-center font-semibold">Capture Shield (Side)</div>
                        </div>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mx-16 my-6 md:mx-40">
                        <img className="h-auto w-[200%]" src={require('./assets/03-shield04-04.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>
    );
}

export default Shield04;
