import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function BPlates01 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-bplates01-m md:bg-bplates01 md:bg-left-top'); 
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px] md:h-[250px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">BALLISTIC PLATES</span>
                        <div className="font-goodtimes text-4xl mt-5 md:text-5xl text-primary-yellow w-3/5 md:w-full">
                            <div>ATG V1 ARMOR LEVEL III</div>
                            <div className="text-xl md:text-2xl">10" X 12"</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="text-[#9D9D9C] font-acier text-4xl mt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Dimensions: 10” x 12”
                            </li>
                            <li>
                                Weight: 8.5 pounds (approximately)
                            </li>
                            <li>
                                Shelf life: Twenty years (maintenance-free)
                            </li>
                            <li>
                                Level III ballistic protection
                            </li>
                            <li>
                                Rated for 7.62x51 NATO M80 Ball (.308 win) ammunition at 2,780 feet per second
                            </li>
                            <li>
                                Left or right-handed Advanced Shooter’s Cut options for comfort when shouldering rifle
                            </li>
                            <li>
                                Stress fracture-resistant
                            </li>
                            <li>
                                Abrasion & chemical-resistant coating
                            </li>
                            <li>
                                Most independently reviewed & tested armor, worldwide
                            </li>
                            <li>
                                Sold as single plate
                            </li>
                        </ol>
                        <div className="ml-2">MADE IN USA</div>
                    </div>
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        TECH SPECS
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Materials</div>
                        <div className="w-4/5">
                            Polyurea-coated ballistic steel (additional build up polyurea coat available)
                        </div>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            For the ultimate in ballistic protection, the ATG V1 Armor Level III plate is one of the most durable defensive options available. This plate is rated up to 7.62x51 NATO M80 Ball ammunition at 2,780 feet per second, ensuring a range of protection across multiple calibers and projectile speeds (including threats covered by type I, II, IIA and IIIA plates).
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            ATG V1 Armor Level III Armor's Advanced Shooter's Cut (ASC) offers plates with both lefthanded and right-handed draw options for maximum comfort when shouldering a rifle. Multi-Curved models help reduce strain across the plate's surface area upon projectile impact, making for a significantly stronger plate.
                        </div>    
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            Choose between the base polyurea coat, or an additional build up polyurea coat for extra protection against fragmentation and spalling.
                        </div>    
                        <div className="px-6 md:ml-6 mt-5 bg-primary-yellow">
                            Made in the USA with a twenty year, maintenance-free shelf life, the quality of this plate is second to none.
                        </div>    
                    </div>
                    <div className="mt-6 mx-24 md:mx-56">
                        <img className="h-auto w-[200%]" src={require('./assets/02-bplates01-01.jpg')} alt="" draggable="false" />
                    </div>
                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default BPlates01;
