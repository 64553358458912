import { useEffect } from "react";
import { Link } from "react-router-dom";

function Fullbody ({ setBg }) {
    useEffect(() => {
        setBg('bg-fullbody-m md:bg-fullbody md:bg-right-top');
    }, [setBg]);

    return (
        <div className="w-full flex flex-col">
            <div className="h-[380px]" />
            <div className="mr-6 md:ml-12 mb-20 font-goodtimes text-primary-dark-yellow text-right text-6xl md:text-left md:text-8xl md:w-1/2">
                <div>FULL BODY ARMOR GEARS</div>
            </div>
            <div className="mx-6 md:ml-12 md:flex md:flex-row md:w-2/3">
                <div className="mb-12 md:w-1/2">
                    <button className="text-left">
                        <Link to="/armor">
                            <div className="font-goodtimes text-primary-yellow text-4xl md:text-5xl hover:text-[#93B9B8] md:w-1/3 md:mb-3">
                                <div>ATG ARMOR SYSTEM</div>
                            </div>
                            <div className="font-goodtimes text-primary-yellow text-xl md:text-3xl md:mb-3">
                                <div>TYPE IIIA</div>
                            </div>
                            <div className="font-inter text-primary-yellow text-sm md:text-base w-4/5 md:w-2/3">
                                <div>COVER THE MOST VITAL AREAS ON YOUR BODY AND GAIN THE UPPER HAND OVER ANY ENEMY</div>
                            </div>
                        </Link>
                    </button>
                </div>
                <div className="mb-12 md:w-1/2">
                    <button className="text-left">
                        <Link to="/modular">
                            <div className="font-goodtimes text-primary-yellow text-4xl md:text-5xl hover:text-[#93B9B8] md:w-5/6 md:mb-3" >
                                <div>MODULAR VEST FOR TACTICAL OPERATION</div>
                            </div>
                            <div className="font-goodtimes text-primary-yellow text-xl md:text-3xl md:mb-3">
                                <div>ANTI-STAB</div>
                            </div>
                            <div className="font-inter text-primary-yellow text-sm md:text-base w-4/5 md:w-1/2">
                                <div>LEVEL IIIA + STAB1 BULLETPROOF VEST MULTI-THREAT PROTECTION</div>
                            </div>
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Fullbody;
