import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

function Main ({ bg }) {
    return (
        <div className={`flex flex-col h-screen bg-cover bg-no-repeat bg-local ${bg}`}>
            <Navbar />
            <div className="flex grow">
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}

export default Main;