import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Handcuff01 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-handcuff01-m md:bg-handcuff01 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[300px] md:h-[150px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">HANDCUFFS</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-5/6">
                            <div>PEERLESS HIGH SECURITY HINGED HANDCUFFS</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="mt-5 mx-6 md:ml-12">
                        <div className="w-11/12">
                            Offering only the highest order of security, the Peerless High Security Hinged Handcuffs is intended for high risk prisoner restraint and transport situations. This handcuff utilizes a Peerless hinged handcuff as a base for additional control and security. This model also features Medeco high security lock cylinders and accompanying dead bolts with riveted casing. The dead bolts prevent the handcuffs from being shimmed and are available only with Medeco lock systems.
                        </div>
                        <div className="mt-5 w-11/12">
                            Peerless High Security Hinged Handcuffs has a restricted key control system to prevent unauthorized key duplication. Multiple sets can be ordered with two different key options. The locks on each set can all be keyed alike or each set can be keyed different.
                        </div>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mt-5 mx-6 md:ml-12">
                        <div className="w-11/12">
                            Once you register your key code(s), your agency will have exclusive rights to that code (those codes). This handcuff comes with two keys, a pair of double lock activators and a lifetime warranty for manufacture defects.
                        </div>
                    </div>
                    <div className="mx-16 my-6 md:mx-36">
                        <img className="h-auto w-[200%]" src={require('./assets/04-handcuff01-01.jpg')} alt="" draggable="false" />
                    </div>

                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Handcuff01;
