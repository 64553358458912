import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Apparel05 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-apparel05-m md:bg-apparel05 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[280px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">APPAREL</span>
                        <div className="font-goodtimes text-4xl mt-5 md:text-5xl text-primary-yellow w-11/12">
                            <div>ATG ARMORED COMBAT SHIRT</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="mx-6 md:ml-12">
                        <div className="mt-5 font-bold">Comfortable complete protection</div>
                        <div className="w-11/12">
                            The unique armored shirt which gives complete upper body protection with a comfortable fit, increasing your company combat response time. The perfect 2-in-1 - a comfortable shirt and an additional armor.
                        </div>
                    </div>
                    <div className="text-[#9D9D9C] font-acier text-4xl pt-5 mx-6 md:ml-12">
                        FEATURES
                    </div>
                    <div className="bg-primary-yellow pb-5 mx-6 md:ml-12">
                        <ol className="list-decimal pl-8 pr-6 py-2">
                            <li>
                                Expanding the protection area - Armor inserts are virtually invisible at first sight but they are designed to offer the maximal possible area of protection for the operator
                            </li>
                            <li>
                                Super lightweight - Unlike heavy plate systems, the reduced weight means that you can wear this shirt daily practically around the clock without any issues and stay comfortable. It is easy to put on and to remove
                            </li>
                            <li>
                                Materials matter - We follow MIL-SPEC standards to ensure uncompromising functionality, durability, and longevity in combat conditions. Our products are built to last – manufactured with a quality you can rely upon
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="flex flex-col md:flex-row md:mx-6">
                        <div className="m-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel05-01.png')} alt="" draggable="false" />
                        </div>
                        <div className="m-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel05-02.png')} alt="" draggable="false" />
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:mx-6">
                        <div className="m-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel05-03.png')} alt="" draggable="false" />
                        </div>
                        <div className="m-6 grow">
                            <img className="h-auto w-[200%]" src={require('./assets/02-apparel05-04.png')} alt="" draggable="false" />
                        </div>
                    </div>
                 </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">
                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Apparel05;
