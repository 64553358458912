import { useEffect } from "react";
import Logo from "./Logo";
import ContactForm from "./ContactForm";

function Shield01 ({ setBg, toppage }) {
    useEffect(() => {
        setBg('bg-shield01-m md:bg-shield01 md:bg-left-top');
        toppage.current?.scrollIntoView({behavior: 'smooth'});
    }, [setBg, toppage]);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row">
                <div className="md:w-1/3">
                    <div className="h-[293px] md:h-[320px]" />
                    <div className="md:mx-12 mx-6 mb-12">
                        <span className="align-middle font-goodtimes text-xl md:text-3xl px-5 py-2 text-primary-darker-yellow bg-primary-yellow leading-tight">SHIELD</span>
                        <div className="font-goodtimes text-4xl mt-5 mb-3 md:text-5xl text-primary-yellow w-5/6">
                            <div>RIOT SHIELD</div>
                        </div>
                    </div>
                </div>
                <div className="md:block hidden md:w-2/3 md:pl-96 md:pr-56 md:pt-24">
                    <Logo />
                </div>
            </div>
            <div className="bg-white flex flex-col md:flex-row md:justify-between md:bg-btmbg md:bg-contain md:bg-right-bottom">
                <div className="md:w-1/3">
                    <div className="mt-5">
                        <div className="mx-6 md:ml-12 w-10/12">
                            Designed with a security professional in mind, this extremely resistant shield is made for law enforcement and professional use. The ergonomically shaped reinforced plate of the shield efficiently dampens impacts and vibration allowing a lightweight protection device to be used in crowdcontrol situations.
                        </div>
                        <div className="mx-6 md:ml-12 w-10/12 mt-5">
                            The shield is fitted with highquality, extremely resistant polycarbonate with thickness of 3mm and has a V-shaped form that conducts blows efficiently. Rubber handles are equipped with deep reedings, which ensure a secure grip and is slip-resistant when held by one hand or two.
                        </div>    
                    </div>
                    <div className="mx-8 my-6 md:mx-20">
                        <img className="h-auto w-[200%]" src={require('./assets/03-shield01-01.jpg')} alt="" draggable="false" />
                    </div>

                    <div className="mx-6 md:ml-12">
                        <div className="font-bold">Shield</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Extremely Resistant Polycarbonate
                                </li>
                                <li>
                                    Width : 57 cm
                                </li>
                                <li>
                                    Height : 100 cm
                                </li>
                                <li>
                                    Thickness : 3 mm
                                </li>
                                <li>
                                    Weight : up to 3.5 kg
                                </li>
                                <li>
                                    Color : Transparent
                                </li>
                            </ul>
                        </div>
                        <div className="font-bold mt-3">Handle Grip</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Iron Coated Contoured Rubber (Slip-resistant)
                                </li>
                                <li>
                                    Position : 45 degree
                                </li>
                            </ul>
                        </div>
                        <div className="font-bold mt-3">Stick Holder</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Polycarbonate
                                </li>
                                <li>
                                    Placement : Top and bottom right side of the shield
                                </li>
                                <li>
                                    Bolt : Each stick holder uses 1 bolt
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="md:w-1/3 mb-8">
                    <div className="mx-16 my-6 md:mx-32">
                        <img className="h-auto w-[200%]" src={require('./assets/03-shield01-02.jpg')} alt="" draggable="false" />
                    </div>

                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        RIOT SHIELD
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Shield</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Extremely Resistant Polycarbonate
                                </li>
                                <li>
                                    Width : 57 cm
                                </li>
                                <li>
                                    Height : 100 cm
                                </li>
                                <li>
                                    Thickness : 3 mm
                                </li>
                                <li>
                                    Weight : up to 3.5 kg
                                </li>
                                <li>
                                    Color : Black (with transparent window)
                                </li>
                            </ul>
                        </div>
                        <div className="mt-3 font-bold">Handle Grip</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Iron Coated Contoured Rubber (Slip-resistant)
                                </li>
                                <li>
                                    Position : Vertical
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="mx-16 my-6 md:mx-32">
                        <img className="h-auto w-[200%]" src={require('./assets/03-shield01-03.jpg')} alt="" draggable="false" />
                    </div>
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        RIOT SHIELD (OVERSIZED)
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Shield</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Extremely Resistant Polycarbonate
                                </li>
                                <li>
                                    Width : 57 cm
                                </li>
                                <li>
                                    Height : 170 cm
                                </li>
                                <li>
                                    Thickness : 3 mm
                                </li>
                                <li>
                                    Weight : up to 4.5 kg
                                </li>
                                <li>
                                    Color : Black (with transparent window)
                                </li>
                            </ul>
                        </div>
                        <div className="mt-3 font-bold">Handle Grip</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Iron Coated Contoured Rubber (Slip-resistant)
                                </li>
                                <li>
                                    Position : Vertical
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="px-6 pb-12 md:w-1/3 md:px-12">

                    <div className="mx-16 my-6 md:mx-32">
                        <img className="h-auto w-[200%]" src={require('./assets/03-shield01-04.jpg')} alt="" draggable="false" />
                    </div>
                    <div className="text-black font-acier text-4xl pt-5 mx-6 md:ml-12">
                        RIOT SHIELD (OVERSIZED)
                    </div>
                    <div className="mx-6 md:ml-12">
                        <div className="mt-3 font-bold">Shield</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Extremely Resistant Polycarbonate
                                </li>
                                <li>
                                    Width : 57 cm
                                </li>
                                <li>
                                    Height : 170 cm
                                </li>
                                <li>
                                    Thickness : 3 mm
                                </li>
                                <li>
                                    Weight : up to 4.5 kg
                                </li>
                                <li>
                                    Color : Transparent
                                </li>
                            </ul>
                        </div>
                        <div className="mt-3 font-bold">Handle Grip</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Iron Coated Contoured Rubber (Slip-resistant)
                                </li>
                                <li>
                                    Position : 45 degree
                                </li>
                            </ul>
                        </div>
                        <div className="font-bold mt-3">Stick Holder</div>
                        <div className="w-11/12">
                            <ul className="list-disc pl-5 pr-6 py-2">
                                <li>
                                    Material : Polycarbonate
                                </li>
                                <li>
                                    Placement : Top and bottom right side of the shield
                                </li>
                                <li>
                                    Bolt : Each stick holder uses 1 bolt
                                </li>
                            </ul>
                        </div>

                    </div>

                    <ContactForm />
                </div>

            </div>
        </div>

    );
}

export default Shield01;
